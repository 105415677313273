.welcomeautofill-popup .modal-dialog {
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
}

.welcomeautofill-popup .modal-content {
  box-shadow: 0px 0px 15px #00000040;
  border-radius: 10px;
  padding: 95px 40px 60px;
  background-image: url('/images/autofillpopup-bg.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: left top;
}

.welcomeautofill-popup .modal-body {
  padding: 75px 0 0;
  background-image: url('/images/autofillpopup-icon.png');
  background-size: 170px auto;
  background-repeat: no-repeat;
  background-position: right 20px top;
}

.welcomeautofill-popup h4 {
  font-size: clamp(2rem, 1.5938rem + 1.3542vw, 2.8125rem);
  font-style: normal;
  font-weight: 600;
  line-height: 50px;
  background: var(--linear-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  padding: 0px;
  font-family: var(--kg-primary-font);
}

.welcomeautofill-popup p {
  color: #000;
  font-family: var(--kg-primary-font);
  font-size: clamp(1.125rem, 0.9688rem + 0.5208vw, 1.4375rem);
  font-style: normal;
  font-weight: 400;
  margin: 25px 0 20px;
}

.welcomeautofill-popup .link-buttons {
  display: flex;
  gap: 15px;
}

.closebtn {
  position: absolute;
  right: 22px;
  top: 22px;
  width: 17px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .welcomeautofill-popup .modal-dialog {
    max-width: 90%;
  }
  .welcomeautofill-popup .modal-content {
    padding: 60px 20px;
  }
  .welcomeautofill-popup .modal-body {
    padding: 0;
    background: none;
  }
}
@media (max-width: 580px) {
  .welcomeautofill-popup label:last-child {
    margin-left: 0;
    clear: both;
  }
}
