@use '../utilities/mixins' as mixins;

.section-heading {
  font-size: clamp(2.5rem, 1.3603rem + 2.3774vw, 3.5rem);
  text-align: center;
  color: #000;
}

.feature-heading {
  font: normal normal 600 clamp(2rem, 1.1875rem + 1.8056vw, 2.8125rem) / 70px
    var(--kg-primary-font);
}

.feature-heading,
.section-heading {
  display: flex;
  align-items: center;
  gap: 5px;
}

.feature-heading span,
.section-heading span {
  line-height: 1.3;
  white-space: nowrap;
}

.pricing-container {
  margin: 0;
  background: #fff;
  padding: 5% 0 4% 0;
  position: relative;
  .container {
    width: 92%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .feature-image {
    position: relative;
    top: auto;
    right: auto;
    width: 50%;
    height: 100%;
  }
}

.hero-left-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .subscription-slider-block {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 12px;
  }

  .floating-btns {
    display: flex;
    gap: 10px;
  }

  .hero-block-grid {
    margin-top: 25px;

    > div {
      min-width: 290px;
    }
  }
}

.hero-grid-section {
  width: calc(50% - 12px);
  display: inline-block;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  vertical-align: middle;

  &.first-sec::before {
    @include mixins.addGradientBackground;
  }

  .hero-grid-sub-section {
    font-size: 16px;
    border-radius: 20px;
    padding: 22px 10px;
    position: relative;

    &:first-of-type {
      margin-bottom: 24px;
    }

    &::before {
      @include mixins.addGradientBackground;
    }
  }

  &.first-sec {
    border-radius: 20px;
    padding: 30px 0;
    margin-right: 12px;
    background: var(--linear-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.second-sec {
    margin-left: 12px;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.kg-block {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 0 auto;
  max-width: 1200px;

  .kg-card-container {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin: 0 auto;
    max-width: 1200px;

    .kg-card {
      display: flex;
      justify-content: space-around;
      width: 100%;

      .subscription-description {
        color: #000000;
        padding: 40px;
        background: #fff;
        border-radius: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        border: 2px solid #e5e7eb;

        .card-header {
          text-align: center;
          padding-bottom: 40px;
          border-bottom: 0.5px solid #6b7280;
          height: 120px;

          .sub-plan-heading {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            font: normal normal 600 26px/70px var(--kg-primary-font);
            font-size: 40px;
            font-weight: 600;
          }
        }

        .vertical-line {
          border-top: 1px solid #ededed;
          width: 100%;
          background-color: #4d6af2;
          align-self: stretch;
          width: 2px;
        }

        .plan-button {
          margin-top: auto;
          text-align: center;

          button {
            margin-top: 3.5rem;
            width: 100%;
          }
        }

        .plan-benefits {
          font-weight: 500;
          font-family: var(--kg-primary-font);

          .price-details {
            padding: 0;
            list-style: none;
            text-align: left;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            li {
              font-size: 20px;
              font-family: var(--kg-primary-font);
              width: 100%;
              margin: 0;
              padding: 25px 0 0;
              align-items: center;
              display: flex;
              font-weight: 400;
              white-space: normal;

              > .svgspan,
              .empty-span {
                width: 26px;
                margin-right: 17px !important;
                flex-shrink: 0;
              }
            }
          }
        }
      }
    }
  }
}

.unlimited-downloads-block {
  padding: 160px 0 160px;
  position: relative;
  z-index: 1;
  overflow: hidden;

  .container {
    z-index: 2;
    width: 92%;

    .download-block {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: auto;
      border-radius: 12px;
      padding-bottom: 35px;
    }
  }

  .sprinkles-1,
  .sprinkles-2 {
    position: absolute;
    z-index: 0;
    opacity: 0.5;
  }

  .sprinkles-1 {
    top: -100px;
    left: -50px;
  }

  .sprinkles-2 {
    bottom: -100px;
    right: -100px;
  }

  .image_group {
    width: 100%;
  }
}

.discription {
  font: normal normal normal 26px/39px var(--kg-primary-font);
  text-align: center;
  color: #000;
}

.smart-resize-block {
  .container {
    display: flex;
    max-width: 1000px;
    padding: 100px 100px;
    gap: 50px;

    .smart-resize-image {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .smart-resize-text {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .smart-resize-text-container {
      max-width: 600px;

      p {
        font-family: var(--kg-primary-font);
        font-size: clamp(1.25rem, 0.6802rem + 1.1887vw, 1.75rem);
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }
}

.customer-section {
  margin-bottom: 50px;

  .container {
    max-width: 1000px;
    padding: 50px 100px;
    display: flex;
    align-items: center;
    gap: 30px;

    .customer-text-section,
    .customer-card-block {
      width: 100%;
      font-size: clamp(2.5rem, 1.3603rem + 2.3774vw, 3.5rem);
    }
  }
}

.customer-card-block {
  padding: 30px;
  background: #fff;
  border-radius: 24px;
  display: flex;
  gap: 15px;
  align-items: center;

  .profile-image {
    padding: 1px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid transparent !important;
    background: linear-gradient(
        to right,
        var(--kg-secondary),
        var(--kg-primary)
      )
      border-box !important;
    flex-shrink: 0;

    > span {
      border-radius: 50%;
    }
  }

  .review-text {
    font-size: clamp(0.875rem, 0.4476rem + 0.8915vw, 1.25rem);
    font-weight: 400;
    color: #6b7280;
  }

  .review-name {
    font-size: clamp(1rem, 0.4302rem + 1.1887vw, 1.5rem);
    font-weight: 500;
    display: flex;
    justify-content: space-between;
  }
}

.review-slider-section {
  margin-bottom: 50px;
  padding: 0 50px;
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 100px;

  .review-container {
    padding: 50px;
  }

  .slider-arrow {
    padding: 5px;
    background: linear-gradient(
      90.57deg,
      rgba(250, 67, 133, 0.1) -5.35%,
      rgba(174, 41, 255, 0.1) 88%
    );
    border-radius: 16px;
    display: flex !important;
    position: absolute;
    left: -50px;
    top: 50%;
    cursor: pointer;
    width: 30px;
    height: 30px;

    &.right {
      left: unset;
      right: -50px;
    }

    > span {
      width: 30px;
      height: 30px;
    }

    .review-card {
      max-width: 440px;
      padding: 30px;
      background: #fff;
      display: flex;
      flex-direction: column;
      gap: 30px;
      align-items: center;
      border-radius: 24px;
      margin: 0 15px;

      .profile-name {
        color: #000000;
        font-size: 20px;
      }

      .profile-quote {
        color: #6b7280;
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        margin-bottom: 10px;
        height: 110px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .profile-image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        padding: 1px;
        border: 1px solid transparent !important;
        background: linear-gradient(
            to right,
            var(--kg-secondary),
            var(--kg-primary)
          )
          border-box !important;
        flex-shrink: 0;
        margin: 0 auto;
        margin-bottom: 26px;

        > span {
          border-radius: 50%;
        }
      }
    }

    .slick-track {
      display: flex;
      gap: 30px;
    }
  }
}

.kgpro-faq-block {
  margin-bottom: 100px;
  padding: 100px;
  max-width: 1450px;
  margin: 0 auto;

  .faq-block {
    border-radius: 12px;
    margin: 0 auto;
    padding: 50px;
    background: #fff;

    .choose-plan {
      font-size: 40px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 50px;
    }
  }
}

@media screen and (max-width: 370px) {
  .sub-plan-heading {
    font-size: 24px !important;
  }
  .plan-button button {
    padding: 10px;
    font-size: 14px !important;
  }
}

@media screen and (max-width: 575px) {
  .floating-btns {
    flex-direction: column;
  }
  .customer-card-block .profile-image {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 767px) {
  .download-block {
    height: auto;
    margin-top: 50px;
  }
  .kg-card-container {
    flex-direction: column;
  }
  .subscription-description {
    width: 100%;
  }

  .price-details li {
    font-size: 15px !important;
  }

  .plan-button button {
    padding: 10px;
    font-size: 18px !important;
  }

  .feature-image {
    display: none;
  }
  .subscribe-now-button {
    margin: 0px 0px !important;
    width: 100%;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    height: 48px;
  }
  .price-button {
    margin: 0px 0px !important;
    width: 100%;
    height: 48px;
  }
  .pricing-container {
    padding-top: 100px;
    padding-bottom: 160px;
  }
  .floating-btns {
    width: 100%;
    padding: 0px 20px;
  }

  .floating-btns button {
    width: 100%;
  }
  .image_group {
    width: 100%;
  }
  .icon {
    margin-right: 10px;
  }
  .feature-heading {
    font: normal normal 600 30px/70px var(--kg-primary-font);
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  .subscription-slider-block .image {
    width: 100%;
    height: 45px;
  }
  .subscription-slider-block .image img {
    width: auto;
    height: 100%;
    margin: auto;
  }
  .discription {
    font-size: 20px;
    line-height: 30px;
  }
  .sub-plan-heading {
    font-size: 26px;
  }
  .top-box {
    height: auto;
  }
  .scale-25 {
    transform: scale(1);
  }

  .many-designs-heading {
    flex-direction: column;
    gap: 10px;
  }

  .smart-resize-block .container {
    flex-direction: column;
    padding: 100px 50px;
  }

  .smart-resize-image {
    order: 1;
  }

  .smart-resize-text {
    order: 2;
  }

  .customer-section .container {
    flex-direction: column;
    align-items: center;
    padding: 50px 30px;
  }

  .customer-text-section {
    text-align: center;
  }

  .customer-card-block .profile-text {
    align-items: center;
  }

  .review-slider-section {
    padding: 0 20px;
  }

  .review-card .profile-quote {
    font-size: 14px;
    height: 90px;
  }

  .review-card .profile-name {
    color: #000000;
    font-size: 18px;
  }

  .review-slider-section .review-container {
    padding: 30px;
  }

  .review-slider-section .slider-arrow {
    width: 25px;
    height: 25px;
  }

  .section-heading {
    font-size: 24px;
    font-weight: 600;
    font-family: var(--kg-primary-font);
    white-space: nowrap;
  }

  .discription {
    font-size: 20px;
    line-height: 30px;
  }
}

@media screen and (max-width: 480px) {
  .smart-resize-block .container {
    padding: 100px 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .subscription-description {
    width: 100%;
    padding: 30px 20px;
  }

  .feature-heading {
    font-size: 30px;
  }
  .floating-btns button {
    margin-bottom: 10px;
  }
  .pricing-container {
    padding-top: 100px;
  }
  .price-details li {
    font-size: 18px !important;
  }
  .smart-resize-block .container {
    display: flex;
    max-width: 1000px;
    padding: 100px 50px;
    gap: 30px;
  }
  .customer-section .container {
    padding: 50px;
  }
  .customer-card-block .profile-image {
    width: 75px;
    height: 75px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1377px) {
  .subscription-description {
    padding: 40px 25px;
  }
  .feature-heading {
    font-size: 38px;
  }
  .price-details li {
    font-size: 18px !important;
  }
}
