/* Search Page Css */

.searchpgouterblock .home_search_form {
  max-width: 500px;
  margin: 10px auto 35px;
}

.searchpgouterblock .home_search_form .form-control {
  border: 2px solid #cfcfcf;
}

.searchpg-results {
  margin-top: 190px;
  width: 100%;
  display: block;
}

.searchpg-results .catproduct-single {
  width: 100%;
  float: none;
  display: inline-block;
}

.skeleton {
  column-span: all;
  overflow: hidden;
  float: none;
  display: inline-block;
  margin-bottom: 35px;
  width: 90%;
}

.skeleton-image {
  width: 100%;
  height: 250px;
}

.skeleton-text {
  margin-top: 10px;
  width: 100%;
  height: 20px;
}

.empty-search-page {
  text-align: center;
}

.masonry-layout {
  position: relative;
}

.masonry-layout > div {
  position: absolute;
  padding: 0 10px;
}
