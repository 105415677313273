/* Contact Page Css */

.contactpg-outer {
  max-width: 45%;
  margin: 0 0 20px;
  float: left;
}

.contactpg-outer textarea {
  min-height: 100px;
}

@media (max-width: 991px) {
  .contactpg-outer {
    max-width: 65%;
  }
}

@media (max-width: 767px) {
  .contactpg-outer {
    max-width: 80%;
  }
}

@media (max-width: 580px) {
  .contactpg-outer {
    max-width: 100%;
  }
}
