.autofill-logo-imgouter {
  border: 1px solid #999999 !important;
  display: flex;
  position: relative;
  float: left;
  border-radius: 5px;
}

.autofill-logo-imgouter span {
  position: unset !important;
  width: 100% !important;
  float: left;
  margin: 0px !important;
  height: 40px !important;
}

.autofill-logo-img {
  object-fit: cover;
  width: 100% !important;
  position: relative !important;
  height: 100% !important;
  /* border-radius: 50px !important; */
}

.autofill-logo-imgouter svg {
  position: absolute;
  color: red !important;
  right: -7px;
  background: #fff;
  border-radius: 50px;
  top: -7px;
  cursor: pointer;
}

.logo-file-input-hidden {
  display: none !important;
}

.logo-file-input {
  width: 50px;
  height: 38px;
  border: 1px solid #999999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: var(--hover-gradient);
  cursor: pointer;
}
