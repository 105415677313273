.layer-list-tile {
  display: block;
  background: #ebecef;
  margin-bottom: 8px;
  padding: 8px 5px;
  border-radius: 5px;
  border: 2px solid transparent;
  &.selected {
    border: 1px solid var(--editor-text);

    .chakra-editable {
      color: var(--editor-text);
    }
  }
}

.layer-tile-list-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.drag-handle {
  cursor: pointer;
}

.layer-type-text {
  width: 60px;
}

.layer-element-name {
  width: 100%;
  max-width: 140px;
}

.chakra-editable {
  display: flex;
  width: 100%;
  color: var(--editor-light-text);
  input {
    padding: 5px;
  }
}

.layer-element-name-text {
  width: 100%;
}

.layer-tile-buttons {
  margin-left: auto;
  display: flex;
  gap: 5px;
  button {
    background: #d1d5db;
    padding: 10px 5px !important;
    width: unset;
    height: unset;
    min-width: unset;
    max-width: unset;
    &:hover {
      background: #adb1b8;
      outline: 1px solid #ffffffc4 !important;
    }
    &:active {
      transform: scale(0.95);
    }
  }
}
