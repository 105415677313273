/* Home Products Block */

.home-products-block {
  display: inline-block;
  position: relative;
  width: 100%;
  padding: 65px 60px 35px;
  margin: 60px 0 0;
  background-color: #fff;
  border-radius: 13px;
  overflow: hidden;
}

.home-products-block::before,
.home-products-block::after,
.home-products-block .background {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-position: left top;
  background-repeat: no-repeat;
  background-size: auto;
  border-radius: 13px 0 0 0;
}

.home-products-block .background .sprinkles {
  position: absolute;
  transform: scale(0.75);
  top: -30%;
}

.home-products-block .background .sprinkles.second {
  top: 50%;
  right: 0;
}

.home-products-block .sprinkles.second {
  background-position: right bottom;
  border-radius: 0 0 13px 0;
}

.home-products-block h3 {
  font-size: 40px;
  line-height: 45px;
  font-family: var(--kg-primary-font);
  color: #000;
  float: left;
  width: 100%;
  margin: 0 0 13px;
  padding: 0px;
  font-weight: 500;
  text-align: center;
  position: relative;
  z-index: 9;
}

.home-products-block h3 label {
  margin: 0;
  background: var(--linear-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-products-block > p {
  font-size: 26px;
  line-height: 37px;
  font-family: var(--kg-primary-font);
  color: #000;
  float: left;
  width: 100%;
  margin: 0 0 38px;
  padding: 0px;
  text-align: center;
  position: relative;
  z-index: 9;
}

.userhomepgcontent .home-products-block {
  padding: 0;
  margin: 40px 0 0;
  background-color: transparent;
}

.userhomepgcontent .home-products-block::before,
.userhomepgcontent .home-products-block::after {
  display: none;
}

.userhomepgcontent .home-products-block h3 {
  text-align: left;
  margin: 0 0 5px;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
}

.userhomepgcontent .home-products-block > p {
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  margin-bottom: 30px;
  color: #6b7280;
}

.userhomepgcontent .home-products-block .featuredproduct-single h4 {
  font-size: 16px;
  line-height: 23px;
}

@media (max-width: 767px) {
  .home-products-block {
    padding: 40px 15px;
  }

  .home-products-block::before {
    background-image: url('/public/images/homepage/home-productsbg1-mobile.jpg');
    display: none;
  }

  .home-products-block::after {
    background-image: url('/public/images/homepage/home-productsbg2-mobile.jpg');
  }

  .home-products-block h3 {
    font-size: 28px;
    line-height: 38px;
  }

  .home-products-block > p {
    font-size: 17px;
    line-height: 27px;
  }

  .userhomepgcontent .home-products-block > p a {
    float: none;
    margin: 10px 0 0;
    clear: both;
    display: table;
  }
}
