.procat-block {
  display: table;
  width: 100%;
  color: #000 !important;
}

.procat-block > .chakra-stack > .chakra-form__label {
  margin: 0px !important;
}

.procat-block > .chakra-stack > .chakra-stack:nth-child(2),
.procat-block > .chakra-stack > .chakra-stack:nth-child(3) {
  margin-top: 0 !important;
}

.procat-block > .chakra-stack > .chakra-stack:nth-child(2) span {
  margin-bottom: 4px !important;
}

.procat-block > .chakra-stack > .chakra-stack:nth-child(3) .chakra-input {
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
  font-family: var(--kg-primary-font);
  color: #000;
  margin: 0;
  line-height: 20px;
  border-color: #c4c4c4 !important;
  box-shadow: none !important;
  border-radius: 5px;
  height: auto !important;
}

.procat-block > .chakra-stack > .chakra-stack:nth-child(3) .chakra-button {
  display: none !important;
}

.procat-block > .chakra-stack {
  position: relative;
}

.procat-block > .chakra-stack > div:last-child {
  position: absolute;
  left: 0;
  width: 100%;
  top: calc(100% + 5px);
  max-height: 93px;
  overflow: auto;
  padding: 0px !important;
  margin: 0px !important;
}

.chakra-stack > div:last-child > ul {
  border-radius: 0px;
  border: 0px !important;
  background: #f2f2f2;
}
