/* Download Popup Css */

.orderpayment-popup p {
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  color: #3f4652;
  font-family: var(--kg-primary-font);
}

.orderpayment-popup a {
  text-decoration: underline;
}

.coupon-block {
  position: relative;
}

.coupon-block p {
  display: table;
  margin: 0px auto;
  text-decoration: underline;
  cursor: pointer;
}

.auth-popup form .coupon-block .form-control {
  margin-top: 10px;
}

.auth-popup form .coupon-block .btn {
  position: absolute;
  right: 0;
  width: auto;
  bottom: 0;
  padding: 15px 25px;
  border-radius: 0 5px 5px 0;
}
