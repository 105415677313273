.removebackground-popup {
  color: #000;
}

.removebackground-img span {
  position: relative !important;
  height: 350px !important;
}

.removebackground-img img {
  object-fit: contain;
}

.removebackground-popup .modal-content {
  border-radius: 24px;
}

.removebackground-popup .modal-title {
  font-size: 32px;
  line-height: 48px;
  font-weight: 600;
  margin: 30px 0 10px 0;
  text-align: center;
}

.removebackground-popup .modal-header {
  border: none;
  display: unset;
  position: relative;
}

.removebackground-popup .modal-header .btn-close {
  position: absolute;
  top: 30px;
  right: 30px;
}

.removebackground-popup .modal-header svg {
  stroke-width: 9rem;
  stroke: #6b7280;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.removebackground-popup .modal-footer {
  border: none;
  display: unset;
}

.removebackground-popup .bg-rm-footer {
  margin: 30px;
}

.removebackground-popup .btn-hover-outer {
  display: inline-block;
  margin-right: 12px;
}

.removebackground-popup .rb-btn-container {
  padding: 10px 30px 40px 30px;
  width: 100%;
}

.removebackground-popup .rb-btn {
  width: 100%;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  padding: 11px 12px;
  border-radius: 12px;
}

.removebackground-popup .spinner-holder {
  text-align: center;
}

.removebackground-popup .confirm-text-container {
  justify-content: flex-start;
  align-items: center;
  margin-right: 2;
  text-align: center;
}

.removebackground-popup .confirm-btn-container {
  justify-content: flex-end;
  margin-left: auto;
  word-spacing: 2;
  text-align: center;
}
