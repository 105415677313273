.free-filter {
  display: none;
}
.m-6.grid.grid-cols-1.sm\:grid-cols-2.md\:grid-cols-2.lg\:grid-cols-2.xl\:grid-cols-2.gap-4.mt-8
  > div:nth-child(1):first-of-type {
  width: 100% !important;
  height: 100vh !important;
}

.go2497765715 {
  margin: 0px 5px;
  width: 29% !important;
}
.polotno-close-panel {
  background-color: var(--polotno-elements-bg) !important;
  color: var(--editor-text);
  border: solid 5px #ffffff00;
  border-radius: 3px !important;
  margin: 10px !important;
}
.mask-image-holder .polotno-close-panel {
  background-color: unset !important;
  border: 0;
}

img.go3457971912 {
  height: auto !important;
}
.resizebtn-single {
  color: #b1b2b5;
}

.go1599247145 path {
  fill: #293039;
  stroke: rgba(0, 0, 0, 0.3);
}
.bp5-popover2 .bp5-heading,
.bp5-popover2 .bp5-popover2-content {
  color: #000;
}
.go3795987197 .fill {
  fill: #293039;
}
img.go3457971912 {
  height: auto !important;
  max-width: 100%;
  max-height: 100%;
  width: auto;
}
.go2452636514,
.go1434738792 {
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.polotno-close-panel {
  scale: 0.97;
}
.bp5-editable-text::before {
  background: transparent !important;
}
