@use '../utilities/mixins' as mixins;

.pgheading {
  font-size: 30px;
  line-height: 36px;
  color: #000;
  font-weight: 500;
  float: left;
  font-family: var(--kg-primary-font);
  width: 100%;
  margin: 0 0 30px;
}

.pg-content-outer {
  padding: 40px 0;
}

.pg-white-container {
  min-height: 100vh;
  background: #fff;
  padding: 40px 15px;
}

.pg-content-outer .terms-title {
  color: #707070;
  margin: 15px 0;
  margin-top: 50px;
}

.pg-content {
  float: left;
  width: 100%;
}

.pg-content h1,
.pg-content h2,
.pg-content h3,
.pg-content h4,
.pg-content h5,
.pg-content h6 {
  @include mixins.gradientText;
  font-weight: 500;
  font-family: var(--kg-primary-font);
  display: table;
  width: 100%;
}

.pg-content p,
.pg-content li {
  font-size: 15px;
  line-height: 26px;
  color: #707070;
  font-family: var(--kg-primary-font);
}
.pg-content .font18 {
  font-size: 18px;
}
.pg-content a {
  color: #3f3e80;
}

.section-container {
  max-width: 1440px;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  margin: 0 auto;
  padding-bottom: 100px;
  padding-top: 100px;
}

.section-container .text-container {
  width: 40%;
  padding: 15px;
}
.section-container .image-container {
  width: 60%;
  position: relative;
}

.section-container .text-container {
  flex-shrink: 0;
}

.section-container .text-container h4 {
  color: #000;
  font-size: 62px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  font-family: var(--kg-primary-font);
  width: 100%;
  float: left;
  margin: 0 0 13px;
}

@media (max-width: 420px) {
  .pgheading {
    font-size: 30px;
  }
}