.Toastify {
  z-index: 999999 !important;
  position: absolute;
}

/* NProgress Css */

#nprogress {
  pointer-events: none;
  position: relative;
  z-index: 9999999;
}

#nprogress .bar {
  background: var(--linear-gradient);
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow:
    0 0 10px var(--kg-primary),
    0 0 5px var(--kg-primary);
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0, -4px);
  -ms-transform: rotate(3deg) translate(0, -4px);
  transform: rotate(3deg) translate(0, -4px);
}

#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 9999999;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-top-color: var(--kg-primary);
  border-left-color: var(--kg-primary);
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .bar,
.nprogress-custom-parent #nprogress .spinner {
  position: absolute;
}

.bp5-progress-bar.bp5-intent-primary .bp5-progress-meter {
  background: var(--linear-gradient) !important;
}

.bp5-popover-open,
.bp5-tooltip-open {
  color: #000;
  z-index: 1000000;
}

.select-page-portal .bp5-overlay-content {
  z-index: 10000000 !important;
}

.bp5-tooltip {
  border: 1px solid #fff;
  color: #fff;
  border-radius: 3px;
}

.bp5-popover-content label {
  color: #000;
}

.modal-body span.bp5-popover-target {
  display: block !important;
}

.chakra-tab {
  color: #8f949c;
}

.chakra-tab:hover {
  color: #fff;
}

.chakra-tab[aria-selected='true'],
.chakra-tab[data-selected='true'] {
  background: var(--linear-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  border-bottom: 2px solid transparent;
  border-image: var(--linear-gradient);
  border-image-slice: 1;
}

.bp5-popover-transition-container {
  z-index: 1000 !important;
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
}

.bp5-tooltip .bp5-popover-content {
  padding: 5px 7px !important;
  font-size: 12px !important;
  background: #fff !important;
  color: #1c2127 !important;
  border: none !important;
}

.resize-list .bp5-control.bp5-checkbox .bp5-control-indicator,
.auth-popup .bp5-control-indicator {
  border-radius: 50% !important;
}

.resize-list .bp5-control input:checked ~ .bp5-control-indicator,
.auth-popup input:checked ~ .bp5-control-indicator {
  background: var(--linear-gradient) !important;
  color: #fff !important;
  border-radius: 50% !important;
  outline: none !important;
  border: none !important;
}

.bp5-popover-content .bp5-button-group ~ div {
  color: #000;
}

.go1571122789 {
  height: auto !important;
}

.bp5-popover-placement-bottom .bp5-popover-content {
  color: #000 !important;
}

.bp5-slider-unlabeled .bp5-slider-track .bp5-slider-progress:nth-child(2) {
  background: var(--linear-gradient) !important;
}

.bp5-popover-content
  .bp5-control.bp5-switch
  input:checked
  ~ .bp5-control-indicator {
  background: var(--linear-gradient) !important;
}

.css-1bp1goy > option {
  background: #0e1317 !important;
}

.toast-message {
  margin-bottom: 0;
}

.toast-message a {
  color: #2c0fae;
}

.toast-message a:hover {
  text-decoration: underline !important;
}

@media (max-width: 1300px) {
  .polotno-outerblock > .bp5-navbar {
    max-width: 100vw;
  }

  .go2665581081 {
    display: flex;
  }
}
