.home-projects-block {
  float: left;
  width: 100%;
  margin: 40px 0 0;
}

.home-projects-block h3 {
  font-size: 24px;
  line-height: 30px;
  font-family: var(--kg-primary-font);
  color: #000;
  float: left;
  width: 100%;
  margin: 0 0 15px;
  padding: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 15px;
}

.view-more-link {
  font-size: 17px;
  line-height: 20px;
  background: var(--linear-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  float: right;
  margin-left: auto;
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}

.view-more-link:hover {
  -webkit-text-fill-color: var(--kg-primary);
}
