.auth-popup form .btn.subscribepopup-btn {
  padding: 15px;
  font-size: 18px;
  text-transform: inherit;
  line-height: 18px;
  background: var(--linear-gradient);
  border-radius: 8px;
  box-shadow: none !important;
  transition: 0.25s;
}

.auth-popup form .btn.subscribepopup-btn:hover,
.pay-button .btn:hover {
  opacity: 0.7;
}

.subscribepopup-divider hr {
  margin: 1.5rem 0;
  background: #000;
}

.subscribepopup-content {
  float: left;
  width: 100%;
  background: transparent var(--linear-gradient) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 25px 15px 20px;
}

.subscribepopup-content p {
  float: left;
  width: 100%;
  margin: 0;
  color: #fff;
  font-size: 21px;
  line-height: 30px;
}

.subscribepopup-content p label {
  float: left;
  width: 100%;
  font-size: 27px;
  line-height: 28px;
  font-weight: 600;
  margin: 7px 0 22px;
  color: #fff;
}

.auth-popup .btn.subscribepopup-btn1 {
  background: #fff !important;
  float: left;
  width: 100%;
  border-radius: 8px;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none !important;
  padding: 15px 0;
  border-color: none;
  box-shadow: none !important;
  text-transform: inherit !important;
  color: var(--kg-primary) !important;
  font-weight: 600;
  transition: 0.3s;
}

.auth-popup .learn-more-button {
  background: transparent !important;
  float: left;
  width: 100%;
  border-radius: 8px;
  font-size: 18px;
  line-height: 18px;
  text-decoration: none !important;
  padding: 15px 0;
  box-shadow: none !important;
  text-transform: inherit !important;
  color: #fff !important;
  font-weight: 600;
  transition: 0.3s;
  text-align: center;
}

.auth-popup .btn.subscribepopup-btn1:hover {
  transform: translateY(-2.5px);
}

.auth-popup .btn.subscribepopup-btn1:active {
  transform: scale(0.97);
}
