.editor-loading-page {
  width: 100%;
  height: 100%;
}

.loading-topbar {
  padding: 0 15px;
  z-index: 999;
  background: var(--linear-gradient);
  max-width: 100vw;
  height: 50px;
  position: relative;
  box-shadow:
    0 0 0 1px rgba(17, 20, 24, 0.1),
    0 1px 1px rgba(17, 20, 24, 0.2);

  .rounded-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.loading-sidepanel {
  height: calc(100vh - 50px);
  width: calc(90px + 350px);
  background: #f3f4f6;
  z-index: 999999;
  position: relative;
  overflow: hidden;
  display: flex;

  .polotno-side-tabs-inner {
    width: 90px;
    background: #f3f4f6;
  }

  .polotno-side-tabs-outer {
    width: 350px;
    background: #f9fafb;

    .polotno-panel-container {
      padding: 10px;
      padding-bottom: 50px;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .loading-input {
      height: 35px;
      width: 100%;
      margin-bottom: 15px;
    }

    .loading-tags {
      display: flex;
      gap: 10px;
      width: 100%;
      margin-bottom: 15px;

      .loading-tag {
        height: 20px;
        width: 100px;
      }
    }

    .loading-box {
      height: 150px;
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .polotno-side-panel-tab {
    text-align: center;
    padding: 20px 10px;
    .icon-box {
      width: 35px;
      height: 35px;
    }
    div {
      padding: unset !important;
    }
    font-size: 12px;
    &:first-child {
      background: #f9fafb;
    }
  }
}

.loading-toolbar {
  width: 100%;
  height: 50px;
  background: #fff;
  z-index: 99;
  box-shadow:
    0 0 1px rgba(17, 20, 24, 0.1),
    0 1px 1px rgba(17, 20, 24, 0.2);
}

@media (max-width: 800px) {
  .loading-sidepanel {
    display: none;
  }
}
