.view_product_modal_image,
.view_product_modal_details {
  padding-top: 50px;
}

.view_product_modal_image span {
  position: relative !important;
  width: 50% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.view_product_modal_details .product_price {
  width: 58px;
  height: 28px;
  background: #f3f4f6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  text-align: center;
  font: normal normal normal 15px/30px var(--kg-primary-font);
  letter-spacing: 0px;
  color: #6b7280;
  font-weight: 600;
}

.view_product_modal_details .product_title {
  height: 60px;
  text-align: left;
  font-size: 28px;
  line-height: 34px;
  font-family: var(--kg-primary-font), Medium;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000000;
}

.view_product_modal_details .other_text {
  /* height: 24px; */
  text-align: left;
  font: normal normal normal 17px/28px var(--kg-primary-font);
  color: #5d5f61;
}

.view_product_modal_details .customize-btn {
  padding: 10px;
  text-transform: unset;
  font-size: 16px;
  width: 75%;
  max-width: 350px;
  text-align: center;
}

.view_product_modal_details .customize-btn:hover {
  color: #fff;
}

.view_product_modal_details .share-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  margin-left: 8px;
  padding: 3px 10px !important;
  border: none !important;
  background: linear-gradient(
    135deg,
    var(--kg-light-secondary),
    var(--kg-light-primary),
    var(--kg-light-secondary)
  );
  cursor: pointer;
  transition: 0.2s;
  background-size: 400% 100%;
  background-position: 50% 50%;
}

.view_product_modal_details .share-btn svg {
  width: 24px;
  height: 24px;
}

.view_product_modal_details .share-btn:hover {
  background-position: 200% 100%;
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.view_product_modal_details .share-btn:active {
  transform: scale(0.95);
}

.view_product_modal_details .share-btn > span {
  width: 23px !important;
  height: 23px !important;
  transition: 0.2s;
}

.view_product_modal_details .share-btn:hover > span {
  width: 25px;
  height: 25px;
}

.view_product_modal .row {
  padding-bottom: 60px;
}

.view_product_modal .related_products_heading {
  height: 51px;
  text-align: center;
  font-size: 28px;
  line-height: 30px;
  font-family: var(--kg-primary-font), Medium;
  font-weight: 500;
  letter-spacing: 0px;
  color: #000000;
  padding-top: 20px;
}

.view_product_modal .related_product_list {
  padding: 20px;
}

.view_product_model .modal-header {
  border-bottom: none;
}

.view_product_modal .featuredproduct-single-img {
  object-fit: fill;
}

.view_product_modal .related_product_list .featuredproduct-single-img {
  object-fit: unset !important;
  min-height: auto !important;
}

.view_product_modal .featuredproduct-single {
  width: 12.57%;
  transition: transform 0.2s;
}

.relatedproducts.popup {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}

.relatedproducts.popup .relatedproduct-single {
  width: 18%;
  margin: 0px;
}

.view_product_modal .featuredproduct-single:hover {
  transform: scale(1.1);
}

.product-skeleton-card {
  overflow: hidden;
  display: unset;
}

.product-skeleton-card .product-card {
  aspect-ratio: 3/4;
  width: 100%;
}

.featuredproduct-single.relatedproduct-single.product-skeleton .product-card {
  height: 100%;
}

.featuredproduct-single.relatedproduct-single.product-skeleton span {
  width: 100%;
  height: 100%;
  display: block;
}

.view_product_model .view_product_modal_image,
.view_product_model .view_product_modal_details {
  padding-top: 0px;
}

.view_product_model .view_product_modal_image span {
  width: 85% !important;
}

.view_product_model .view_product_modal_top {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.view_product_model .view_product_modal .row {
  padding-bottom: 50px;
}

.view_product_model .view_product_modal_details .product_title {
  height: auto;
  margin: 0 0 40px;
}

.product_customizebtns {
  display: flex;
  margin-bottom: 30px;
}

.view_product_model .view_product_modal_details .product_price {
  margin: 20px 20px 0 0;
  float: left;
}

.view_product_model .other_text.product_type {
  margin: 22px 0 0 0;
  float: left;
}

.view_product_model .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 992px) {
  .view_product_model .modal-dialog {
    max-width: 880px !important;
  }
}

@media (max-width: 991px) {
  .relatedproducts.popup {
    column-count: 4;
  }

  .view_product_modal_image span {
    height: auto !important;
    width: 70% !important;
  }

  .view_product_modal_details .product_price {
    width: 45px;
    height: 25px;
    font: normal normal normal 12px/26px var(--kg-primary-font);
  }

  .view_product_modal_details .product_title {
    font-size: 18px;
    line-height: 30px;
  }

  .view_product_modal_details .options-btn {
    width: 38px;
    height: 40px;
    margin-left: 5px;
  }

  .view_product_modal_details .other_text {
    font-size: 14px;
    line-height: 20px;
    height: unset;
  }

  .view_product_modal .row {
    padding-bottom: 25px;
  }

  .view_product_modal .related_products_heading {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .relatedproducts.popup .relatedproduct-single {
    width: 23%;
  }

  .view_product_modal_details .customize-btn {
    font-size: 14px;
  }
}

@media (max-width: 580px) {
  .relatedproducts.popup .relatedproduct-single {
    width: 30%;
  }

  .view_product_model .view_product_modal_top > div {
    width: 100%;
  }

  .view_product_modal .view_product_modal_top > div {
    width: 100%;
  }

  .view_product_modal_image,
  .view_product_modal_details {
    padding-top: 0px;
    padding-bottom: 30px;
  }

  .view_product_modal_details .product_title {
    height: unset;
    font-size: 22px;
  }

  .view_product_modal_details .other_text {
    font-size: 16px;
  }

  .view_product_modal .related_product_list {
    padding: 20px 15px;
  }

  .view_product_modal .row {
    padding-bottom: 0px;
  }

  .view_product_modal .featuredproduct-single {
    max-width: unset;
    padding: 0px 5px;
    margin-bottom: 15px;
  }

  .view_product_modal .featuredproduct-single a {
    max-width: 220px;
  }
}

@media (min-width: 576px) {
  .view_product_model .modal-dialog {
    max-width: 95%;
  }
}

@media (max-width: 480px) {
  .relatedproducts.popup .relatedproduct-single {
    width: 45%;
  }
}
