.show-video-modal .modal-header {
  border-bottom: none;
}

.show-video-modal-container {
  padding: 15px 20px;
}

.show-video-modal-dialog {
  max-width: 600px;
}

.show-video-modal-container .flex-container {
  display: flex;
  gap: 30px;
  align-items: center;
}

.show-video-modal-container .flex-container .image-container {
  flex-shrink: 0;
  padding-bottom: 5px;
}

.show-video-modal-container .video-container {
  width: 100%;
  aspect-ratio: 16 / 9;
  margin: 15px auto;
}

.show-video-modal-container .text-container h5 {
  color: #000000;
}

.show-video-modal-container .text-container p {
  color: #4b5563;
}

.show-video-modal-container .text-container .little-text {
  color: #374151;
  display: inline-flex;
  align-items: center;
  font-size: 11px;
}

.show-video-modal-container
  .text-container
  .little-text
  input
  ~ .bp5-control-indicator {
  border-radius: 50%;
  margin-right: 5px;
}

.show-video-modal-container
  .text-container
  .little-text
  input:checked
  ~ .bp5-control-indicator {
  background: var(--linear-gradient);
}

.show-video-modal-container .btn-container {
  display: flex;
  gap: 15px;
  align-items: flex-start;
  margin-top: 25px;
}

.show-video-modal-container .btn-container button,
.show-video-modal-container .btn-container div {
  width: 100%;
  text-align: center;
}

.show-video-modal-container .btn-container div > span {
  color: #6b7280;
  font-size: 12px;
  text-align: center;
  margin-top: 8px;
  display: inline-block;
  cursor: pointer;
}
