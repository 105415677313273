.announcement-bar {
  background: linear-gradient(
    91deg,
    var(--kg-secondary) -5.35%,
    var(--kg-primary) 88%
  );
}

.announcement-bar > div {
  position: relative;
  max-width: 1450px;
  margin: auto;
  padding: 8px;
}

.announcement-bar > div > div {
  color: white;
  text-align: center;
  font-size: 12px;
}

.announcement-bar > div > div > p {
  margin: 0;
}

.announcement-bar .ann-btn {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 12px;
  height: 12px;
}

.announcement-bar .ann-btn > svg {
  stroke-width: 7rem;
  stroke: white;
}
