/* Footer Css */

footer {
  background: #fff 0% 0% no-repeat padding-box;
  padding: 50px 0 35px;
}

footer .col-md-12 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 50px;
}

.footer_left {
  float: left;
}

.footer_left span {
  float: left;
  height: auto;
}

.footer_left img {
  float: left;
}

.footer_center {
  padding: 0 15px;
  display: inline-block;
}

.footer_center ul {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 30px;
}

.footer_center ul li {
  float: left;
  padding: 5px 10px;
  margin: 0;
  list-style: none;
  color: #fff;
  text-align: center;
}

.footer_center ul li a {
  color: #5c5c5c;
  text-align: center;
  font-family: var(--kg-primary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.footer_center ul li a:hover {
  background: var(--linear-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer_center ul li a::after {
  margin-top: 3px;
  content: '';
  display: block;
  background-color: #ffffff;
  width: 0;
  height: 2px;
  transition: width 0.3s ease-in-out;
  display: none;
}

.footer_center ul li a:hover::after {
  width: 90%;
}

.social-mobile {
  display: none;
}

.social-mobile img {
  height: 22px !important;
}

.footer_right {
  float: right;
}

.footer_right ul {
  float: right;
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer_right ul li {
  float: left;
  padding: 0 0 0 15px;
  margin: 0;
  transition: 0.2s ease-in-out;
}

.footer_right ul li:first-child {
  padding: 0;
}

.footer_right ul li img {
  float: left;
  height: 23px !important;
}

.footer_right ul li a {
  display: flex;
}

.footer_right ul li:hover {
  transform: scale(1.15);
}

.footer_copyright {
  float: right;
  clear: both;
  margin: 30px 0 0;
  width: 100%;
}

.footer_copyright p {
  color: #5c5c5c;
  font-family: var(--kg-primary-font);
  font-size: 12px;
  line-height: 20px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin: 0;
}

.footer_copyright p span {
  margin-right: 15px;
}

.footer_copyright .gorin-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.footer_copyright .gorin-logo span {
  margin: 0;
}

@media (max-width: 1130px) {
  .footer .col-md-12 {
    gap: 30px;
  }

  .footer_center ul {
    justify-content: start;
    width: 100%;
    gap: 0;
  }

  .footer_center ul li {
    width: 25%;
    text-align: center;
  }
}

@media (max-width: 991px) {
  .footer_center {
    padding: 0 10px;
  }

  footer .col-md-12 {
    gap: 15px;
  }

  .footer_center ul li {
    width: 33%;
    text-align: center;
    padding: 5px;
  }
  .no-scroll {
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  footer .col-md-12 {
    flex-direction: column;
    gap: 30px;
    align-items: start;
  }

  .footer_center {
    max-width: inherit;
    padding: 0;
    width: 100%;
  }

  .footer_center ul {
    justify-content: space-around;
    row-gap: 10px;
  }

  .footer_center ul li {
    list-style: none;
    text-align: center;
    width: 30%;
  }

  .footer_right {
    width: 100%;
  }
  .footer_copyright {
    border-top: 1px solid #d9d9d9;
    padding: 13px 0 0;
  }
  .footer_right ul {
    display: none;
  }
  .footer_center ul li.social-mobile {
    display: block;
  }
  .footer_center ul li.social-mobile a {
    margin-right: 15px;
  }
}

@media (max-width: 520px) {
  .footer_center ul li {
    width: 45%;
  }

  .footer_center ul li a,
  .footer_copyright p {
    font-size: 14px;
  }

  .footer_copyright p span {
    margin: 0;
  }
}
