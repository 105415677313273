.white-label-banner {
  display: grid;
  margin: 110px 0;
  margin-top: clamp(50px, 6vw, 110px);
  place-items: center;
}

.white-label-banner .white-label-banner-head {
  width: clamp(70%, 972px, 100%);
  text-align: center;
}

.white-label-banner .white-label-banner-head h1 {
  font-size: clamp(32px, 4vw, 60px);
  line-height: clamp(35px, 4vw, 66px);
  font-weight: 700;
  margin-bottom: 20px;
}

.white-label-banner .white-label-banner-head span {
  font-size: clamp(16px, 1.8vw, 24px);
  line-height: clamp(24px, 1.8vw, 36px);
}

.white-label-banner .white-label-banner-head div {
  margin-top: 30px;
  margin-bottom: 40px;
}

.white-label-banner .white-label-banner-products {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, calc(20% - 8px));
  gap: 10px;
}

.white-label-banner .white-label-banner-products div {
  height: max-content;
}

.white-label-banner .white-label-banner-products img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.white-label-banner-head .btn-hover-outer > button {
  font-size: 16px;
  padding: 12px 24px;
  border-radius: 12px;
}

.pointing-image > span {
  max-width: unset !important;
  width: 105.2% !important;
  z-index: 2;
}

@media (max-width: 767px) {
  .white-label-banner .white-label-banner-products {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .white-label-banner .white-label-banner-products > div:nth-of-type(3) {
    display: none; /* Hide the 3rd product */
  }
}

.white-label-promotional-block {
  margin: 110px clamp(15px, 4vw, 80px);
}

.white-label-promotional-block > div {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

.white-label-promotional-block > div:first-of-type {
  padding-right: 40px;
}

.white-label-promotional-block > div:nth-of-type(2) {
  padding: 20px;
  position: relative;
}

.template-block h2,
.membership-block > div:first-of-type h2,
.white-label-promotional-block h2 {
  font-size: clamp(32px, 4vw, 60px);
  line-height: clamp(35px, 4vw, 66px);
  font-weight: 700;
  margin-bottom: 20px;
}

.membership-block > div:first-of-type h3,
.white-label-promotional-block h3 {
  font-size: clamp(24px, 4vw, 36px);
  line-height: clamp(26.4px, 4vw, 39.6px);
  font-weight: 700;
  margin-bottom: 35px;
}

.template-block > div > p,
.membership-block .promo-text-container > div > span,
.white-label-promotional-block span {
  font-size: clamp(16px, 1.8vw, 24px);
  line-height: clamp(24px, 1.8vw, 36px);
}

.membership-block .promo-text-container,
.white-label-promotional-block .promo-text-container {
  display: grid;
  gap: 30px;
}

.membership-block .promo-text-container > div,
.white-label-promotional-block .promo-text-container > div {
  display: grid;
  grid-template-columns: 50px auto;
  gap: 20px;
}

.membership-block .promo-text-container .svg-icon,
.white-label-promotional-block .promo-text-container .svg-icon {
  margin-top: 4px;
}

.membership-block .promo-text-container .svg-icon svg,
.white-label-promotional-block .promo-text-container .svg-icon svg {
  width: 28px;
  height: 28px;
}

.white-label-promotional-block .promo-text-container > div > * {
  vertical-align: top;
}

.white-label-promotional-block > div:nth-of-type(2) > span:nth-of-type(2) {
  font-size: clamp(12px, 1vw, 18px);
  line-height: clamp(14px, 1vw, 19.8px);
  color: #ae29ff;
  width: clamp(200px, 18vw, 300px);
  position: absolute;
  left: 16%;
  bottom: clamp(2.5%, 6vw, 4.5%);
}

.white-label-promotional-block .btn-hover-outer {
  margin-top: 30px;
}

.white-label-promotional-block .btn-hover-outer > button {
  font-size: 16px;
  padding: 12px 24px;
  border-radius: 12px;
}

@media (max-width: 991px) {
  .white-label-promotional-block > div:first-of-type {
    width: 100%;
    padding-right: 0;
  }

  .white-label-promotional-block > div:nth-of-type(2) {
    width: 100%;
    padding: 0;
    margin-top: 50px;
  }

  .white-label-promotional-block > div:nth-of-type(2) > span:nth-of-type(2) {
    font-size: clamp(12px, 2vw, 36px);
    line-height: clamp(14px, 2vw, 39.6px);
    width: clamp(100px, 36vw, 600px);
    text-align: center;
    bottom: 10px;
    left: 25%;
  }
}

@media (max-width: 520px) {
  .white-label-promotional-block > div:nth-of-type(2) > span:nth-of-type(2) {
    bottom: -3%;
  }
}

@media (max-width: 420px) {
  .white-label-promotional-block > div:nth-of-type(2) > span:nth-of-type(2) {
    bottom: -8%;
  }
}

@media (max-width: 380px) {
  .white-label-promotional-block > div:nth-of-type(2) > span:nth-of-type(2) {
    bottom: -13%;
  }
}

.view-demo-form {
  border-radius: 24px;
  padding: 48px 40px;
  border: 2px solid #e5e7eb;
}

.view-demo-form > div:first-of-type {
  text-align: center;
  margin-bottom: 22px;
}

.view-demo-form > div:first-of-type > h2 {
  font-size: clamp(32px, 3vw, 40px);
}

.view-demo-form > div:first-of-type > span {
  font-style: italic;
  font-size: 20px;
  display: block;
  width: 540px;
}

.view-demo-form .demo-form-input {
  margin-top: 8px;
}

.view-demo-form .demo-form-input label {
  line-height: 2.5;
  font-size: clamp(14px, 3vw, 20px);
}

.view-demo-form .demo-form-input span {
  color: #f75426;
  font-size: 16px;
  margin-left: 8px;
}

.view-demo-form .demo-form-input input {
  width: 100%;
  display: block;
  border: 1px solid #9ca3af;
  border-radius: 12px;
  padding: 12px 16px;
  font-size: clamp(16px, 3vw, 20px);
}

.view-demo-form .button-contained {
  width: 100%;
  margin-top: 38px;
  font-size: 24px;
  border-radius: 13px;
  padding: 12px;
}

.membership-block {
  margin: 110px clamp(15px, 4vw, 80px);
}

.membership-block > div {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

.membership-block > div:first-of-type {
  padding-right: 40px;
  margin-top: 80px;
}

.membership-block > div:nth-of-type(2) {
  padding: 20px;
  position: relative;
}

.membership-block > div:first-of-type > span {
  font-size: clamp(20px, 1.8vw, 28px);
  line-height: clamp(30px, 1.8vw, 42px);
}

.membership-block .promo-text-container {
  margin-top: 20px;
}

.membership-block .promo-text-container > div > * {
  vertical-align: middle;
}

@media (max-width: 767px) {
  .view-demo-form > div:first-of-type > span {
    width: unset;
  }
}

@media (max-width: 991px) {
  .membership-block > div:first-of-type {
    width: 100%;
    padding-right: 0;
  }

  .membership-block > div:nth-of-type(2) {
    width: 100%;
    padding: 0;
    margin-top: 50px;
  }
}

.template-block {
  margin: 140px clamp(15px, 4vw, 80px);
  position: relative;
}

.template-block > span:first-of-type {
  position: absolute !important;
  z-index: 0;
  right: -30px;
  top: -30px;
  width: 150px !important;
}

.template-block > span:nth-of-type(2) {
  position: absolute !important;
  z-index: 0;
  left: -30px;
  top: clamp(500px, 25vw, 675px);
  width: 150px !important;
}

.template-block > div {
  text-align: center;
  background: white;
}

.template-block > div:first-of-type {
  background: white;
  position: relative;
  z-index: 1;
}

.template-block > div:nth-of-type(2) {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-top: 40px;
  z-index: 1;
  position: relative;
  background: white;
}

.template-block > div > p:first-of-type {
  margin-bottom: 30px;
}

.template-block > div:nth-of-type(2) > div {
  position: relative;
}

.template-block .btn-hover-outer > button {
  font-size: 16px;
  padding: 12px 24px;
  border-radius: 12px;
  margin-top: 40px;
}

@media (max-width: 991px) {
  .template-block > div > p:first-of-type {
    display: none;
  }

  .template-block .btn-hover-outer {
    display: none;
  }

  .template-block > div:nth-of-type(2) {
    grid-template-columns: 1fr 1fr;
  }

  .template-block > div:nth-of-type(2) > div:nth-of-type(14) {
    display: none;
  }

  .template-block > span:first-of-type,
  .template-block > span:nth-of-type(2) {
    display: none !important;
  }
}

@media (max-width: 520px) {
  .template-block {
    margin: 110px 0;
  }
}
