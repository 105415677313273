.keyboard-container {
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  z-index: 100;
  color: #000;
}

.keyboard-container .keyboard-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-bottom: 5px;
  color: #000;
}

@media (max-width: 420px) {
  .keyboard-container {
    display: none;
  }
}
