.search-box-container {
  width: 100%;
  margin: 45px auto;
  max-width: 840px;
  position: relative;
  display: block;
  padding: 0 10px;
}

.search-box-container .search-bar {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  padding: 11px 16px 11px 45px;
  box-shadow: none !important;
  color: #000;
  border: 2px solid #9ca3af;
  width: 100%;
  border-radius: 50px;

  border: double 2px transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(90.57deg, #9ca3af, #9ca3af);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: 0.2s;
}

.search-box-container .search-bar:focus {
  background-image: linear-gradient(white, white), var(--linear-gradient);
}

.search-box-container
  .search-bar:focus
  + .input-group-btn
  .homesearch-icon-desktop {
  display: none;
}

.search-box-container
  .search-bar:focus
  + .input-group-btn
  .homesearch-icon-mobile {
  display: flex;
}

.search-box-container .btn {
  position: absolute;
  left: 25px;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 0px;
  line-height: 22px;
  border: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.search-box-container .btn i {
  font-size: 22px;
  color: #000;
  opacity: 0.5;
}

.homesearch-icon-desktop {
  display: flex;
}
.homesearch-icon-mobile {
  display: none;
}

.userhomepgcontent .home_search_form .form-control {
  background-color: transparent;
}

.homesearch-result {
  width: 100%;
  margin: -20px auto 0;
  max-width: 740px;
  position: absolute;
  padding: 0 20px;
  left: 0;
  right: 0;
  z-index: 900;
  text-align: left;
}

.homesearch-result ul {
  width: 100%;
  float: left;
  padding: 0px;
  margin: 0;
  list-style: none;
  background: #fff;
  border-radius: 0.375rem;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15) !important;
  -webkit-box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15) !important;
}

.homesearch-result ul li {
  width: 100%;
  float: left;
  font-size: 15px;
  color: #667280;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.homesearch-result ul li:hover {
  background: #f7f7f7 !important;
}

.homesearch-result ul li:first-child {
  border-radius: 0.375rem 0.375rem 0 0;
}

.homesearch-result ul li:last-child {
  border-radius: 0 0 0.375rem 0.375rem;
}

.searchlist-single-imgouter {
  float: left;
  width: 55px;
  margin-right: 15px;
  position: relative;
}

.searchlist-single-imgouter span {
  position: unset !important;
}

.searchlist-single-img {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
  border-radius: 8px !important;
}

@media (max-width: 767px) {
  .home_search_form {
    margin: 30px auto;
  }

  .home_search_form .form-control {
    border: 2px solid transparent;
    position: relative;
  }
  .userhomepgcontent .home_search_form .form-control {
    background-color: #fafafa;
  }
}
