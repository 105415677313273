.polotno-side-panel {
  background-color: #fff !important;
  box-shadow: none !important;
}

.polotno-side-tabs-container {
  z-index: 10000;
}

.polotno-side-tabs-container::-webkit-scrollbar {
  width: 0px;
}

.polotno-side-tabs-container::-webkit-scrollbar-track {
  background-color: #0e1318;
}

.polotno-side-tabs-container::-webkit-scrollbar-thumb {
  background: rgba(171, 179, 191, 0.6);
}

.polotno-side-tabs-container {
  scrollbar-color: #e5e7eb #9ca3af;
  scrollbar-width: none;
}

.polotno-side-tabs-inner .polotno-side-panel-tab {
  width: 90px !important;
  text-transform: uppercase;
  font-weight: 500;
  font-family: var(--kg-secondary-font);
  height: auto;
  padding: 18px 5px;
}

.polotno-side-panel-tab .icon-box {
  display: inline-block;
  padding: 5px;
  box-shadow: 0px 0px 5px 0px transparent;
}

.polotno-side-panel-tab.active .icon-box {
  padding: 5px;
  box-shadow: 0px 0px 5px 0px #00000026;
  border-radius: 5px;
}

.polotno-side-panel-tab svg {
  margin: 0px auto;
  width: 24px;
  height: auto;
  color: #fff;
}

.polotno-side-panel .active-icon {
  display: none;
}

.polotno-side-panel .white-icon {
  display: none;
}

.polotno-side-panel-tab.active .active-icon,
.polotno-side-panel-tab:hover .active-icon {
  display: block;
}

.polotno-side-panel-tab.active .def-icon,
.polotno-side-panel-tab:hover .def-icon {
  display: none;
}

.polotno-side-panel-tab {
  > div {
    white-space: normal;
  }

  .upload-icon {
    stop {
      stop-color: #1f2937;
    }
  }

  &:hover,
  &.active {
    .upload-icon {
      stop:first-child {
        stop-color: var(--kg-secondary);
      }
      stop:last-child {
        stop-color: var(--kg-primary);
      }
    }
  }
}

.polotno-side-panel-tab > div:first-child {
  line-height: 20px;
}

.polotno-side-panel-tab > div:last-child {
  padding-top: 7px !important;
  font-size: 10px;
  line-height: 15px;
  color: var(--editor-tabs-text);
  font-weight: 500;
}

.polotno-panel-container .bp5-card {
  background-color: #ffffff1f;
}

.polotno-side-panel-tab {
  width: 70px;
}
.polotno-side-tabs-container {
  min-width: auto;
}

.polotno-side-tabs-container {
  background: var(--polotno-side-tabs-bg);
}
.polotno-side-panel-tab:hover,
.polotno-side-panel-tab.active {
  background-color: var(--polotno-tabs-bg) !important;
  color: var(--editor-tabs-text) !important;
  transition: 0.3s !important;
}
.polotno-side-panel-tab {
  width: 75px !important;
}
.polotno-side-panel-tab div:first-child {
  line-height: 20px;
}
.polotno-side-panel-tab div:last-child {
  line-height: 15px !important;
}

.polotno-panel-container {
  box-shadow: none !important;
  background-color: var(--polotno-tabs-bg) !important;
}

.go3960841851.polotno-panel-container {
  padding-bottom: 50px !important;
}

.polotno-panel-container > div {
  scrollbar-color: #e5e7eb #9ca3af;
  scrollbar-width: thin;
}

.polotno-panel-container > div::-webkit-scrollbar {
  width: 8px;
}

.polotno-panel-container > div::-webkit-scrollbar-track {
  background-color: #e5e7eb;
}

.polotno-panel-container > div::-webkit-scrollbar-thumb {
  background: #9ca3af;
}

.polotno-panel-container .bp5-icon > svg:not([fill]) {
  fill: #fff;
}
.polotno-panel-container button.bp5-button {
  background-color: #0e1318;
  color: #fff;
  border: solid 1px #686868;
  padding: 10px;
  transition: 0.3s;
}
.polotno-panel-container button.bp5-button:hover {
  background-color: #293039;
  border: solid 2px #686868;
}

@media (max-width: 500px) {
  .polotno-side-tabs-inner .polotno-side-panel-tab {
    height: 75px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }

  .polotno-side-panel-tab div:last-child {
    width: 100%;
  }

  .polotno-side-tabs-inner .polotno-side-panel-tab {
    height: 75px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }

  .polotno-side-panel-tab div:last-child {
    width: 100%;
  }

  .bp5-navbar.polotno-panel-container {
    bottom: 90px !important;
  }
}
