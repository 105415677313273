/* MyProjects Page Css */

.btn-primary.projectspg-btn {
  float: right;
  width: 75px;
  margin: 0;
  font-size: 14px;
  line-height: 14px;
  font-family: var(--kg-primary-font);
  color: #fff;
  background: var(--linear-gradient);
  border: 0px;
  height: 36px;
}

.btn-primary.projectspg-btn:hover {
  background: var(--kg-primary) !important;
}

.myprojectslistouter {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.myprojectlist-single {
  float: left;
  width: 18.4%;
  margin: 0 2% 25px 0;
  position: relative;
}

.home-projects-block .myprojectlist-single {
  margin-bottom: 30px;
  width: 15%;
}

.myprojectlist-single:nth-child(5n) {
  margin-right: 0;
}

.home-projects-block .myprojectlist-single:nth-child(6n) {
  margin-right: 0;
}

.home-projects-block .myprojectlist-single:nth-child(5n) {
  margin-right: 2%;
}

.myprojectlist-single-img {
  display: flex;
  align-items: center;
  height: 150px;
  overflow: hidden;
  position: relative;
}

.myprojectlist-single:hover .myprojectlist-single-img::before {
  background: #e2e3e6;
}

.myprojectlist-single-img::before {
  background: #f3f4f6;
  border-radius: 7px;
  content: '';
  width: 100%;
  height: 100%;
  top: 15px;
  position: absolute;
  transition: 0.2s;
}

.myprojectlist-single-img span {
  margin: 30px 13px 0 !important;
  height: 180px !important;
}

.myprojectlist-single-img img {
  border-radius: 7px;
  width: 100% !important;
  height: auto !important;
  min-width: inherit !important;
  min-height: inherit !important;
  margin: 0px auto !important;
  transition: transform 0.2s;
}

.myprojectlist-single:hover .myprojectlist-single-img img {
  transform: scale(0.95);
}

.myprojectlist-single h4 {
  float: left;
  width: 100%;
  font-size: 16px;
  line-height: 23px;
  margin: 7px 0 0;
  font-family: var(--kg-primary-font);
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.myprojectlist-single span {
  float: left;
  width: 100%;
  font-size: 12px;
  line-height: 15px;
  margin: 3px 0 0;
  font-family: var(--kg-primary-font);
  color: #888;
  text-transform: capitalize;
}

.myprojectlist-single .myprojectlist-single-actions {
  position: absolute;
  right: 10px;
  top: 25px;
  z-index: 9;
}

.myprojectlist-single .myprojectlist-single-actions > .btn {
  background-color: transparent !important;
  padding: 0px;
  border: 0;
  float: right;
  padding-bottom: 5px;
  display: none;
}

.myprojectlist-single:hover .myprojectlist-single-actions > .btn {
  display: block;
}

.myprojectlist-single-actions.show > .btn {
  display: block;
}

.myprojectlist-single .myprojectlist-single-actions > .btn span {
  width: 25px !important;
}

.myprojectlist-single-actions ul {
  background: #f3f4f6;
  clear: both;
  border-radius: 7px;
  margin: 0;
  list-style: none;
  padding: 5px;
  float: left;
  display: none;
}

.myprojectlist-single .myprojectlist-single-actions:hover ul {
  display: block;
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.myprojectlist-single .myprojectlist-single-actions ul.show {
  display: block;
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.myprojectlist-single-actions ul li {
  font-size: 15px;
  line-height: 20px;
  color: #333;
  margin: 2px 0;
  float: left;
  clear: both;
  padding: 6px;
  width: 100%;
  border-radius: 6px;
}

.myprojectlist-single-actions ul li:hover {
  background: #fff;
}

.myprojectlist-single-actions ul li .btn {
  background-color: transparent !important;
  padding: 0px;
  border: 0;
  color: #666;
  font-size: 12px;
  line-height: 17px;
  font-family: var(--kg-primary-font);
  display: flex;
  align-items: center;
}

.myprojectlist-single-actions ul li .btn > span {
  margin-right: 8px !important;
}

.legacyprojects-heading {
  margin-top: 30px;
}

.pg-content-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.pg-content-header .pgheading,
.pg-content-header .projectspg-btn {
  float: unset;
}

.pgouterblock .warning-text {
  text-align: center;
  font-size: 1.1rem;
  padding: 10px;
}

.pgouterblock .warning-text a {
  color: var(--kg-primary);
}
.pgouterblock .warning-text a:hover {
  color: #779dff;
  text-decoration: underline;
}

@media (max-width: 767px) {
  .myprojectlist-single,
  .home-projects-block .myprojectlist-single {
    width: 32%;
  }

  .myprojectlist-single:nth-child(5n) {
    margin-right: 2%;
  }

  .myprojectlist-single:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 420px) {
  .myprojectlist-single,
  .home-projects-block .myprojectlist-single {
    width: 49%;
  }

  .myprojectlist-single:nth-child(3n) {
    margin-right: 2%;
  }

  .myprojectlist-single:nth-child(2n) {
    margin-right: 0;
  }
}
