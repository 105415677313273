.resize-popover {
  z-index: 1000;
  position: relative;
  margin-top: 5px;
}

.file-popover {
  z-index: 1000;
  position: relative;
  max-width: 350px;
}

.file-popover .bp5-menu-item {
  padding: 12px;
}

.resize-menu {
  padding: 1rem 0;
  min-width: 350px;
  max-width: 400px;
  overflow: auto;
  background: #fff;
  color: #000;
  box-shadow:
    0 0 0 1px rgba(64, 87, 109, 0.07),
    0 2px 12px rgba(53, 71, 90, 0.2);
  border-radius: 4px;
  max-height: 95vh;
  overflow-y: auto;
}

.resize-list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 15px;
}

.resize-list-item {
  padding: 0.3rem 1rem;
}

.resize-list label {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 0;
}

.resize-list-item:hover,
.resize-list-item.active {
  background: rgb(64 87 109 / 7%);
}

.resize-list-label {
  display: flex;
  align-items: left;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
}

.resize-list-label .light {
  font-size: 0.8rem;
  color: rgb(13 18 22 / 70%);
}

.resize-menu .btn-container {
  margin-top: 15px;
  padding: 0 30px;
}

.resize-menu .btn-container button {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 12px;
  padding: 8px 10px;
}

.resize-menu .btn-container button.button-outlined::before {
  border-radius: 12px;
}

.custom-size-container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.numeric-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}

.numeric-container .bp5-input {
  max-width: 60px !important;
}

.numeric-container span {
  color: rgb(13 18 22 / 70%);
  font-size: 0.8rem;
}

.gold-diamond path {
  fill: var(--kg-primary);
}

@media (max-width: 380px) {
  .resize-menu {
    min-width: unset;
    max-width: unset;
    max-height: unset;
  }
}
