/* Sidebar Css */

.pgouterblock {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.pgsidebar {
  float: left;
  width: 20%;
  margin: 45px 0 0;
}

.pgcontent {
  margin-left: auto;
  width: 80%;
  padding: 45px 0 80px 25px;
  float: right;
}

.pgcontentinner {
  float: left;
  width: 100%;
  background-color: #fff;
  padding: 0 30px;
  border-radius: 7px;
}

.sidebarmenu {
  padding: 0;
  float: right;
  width: 100%;
  list-style: none;
  margin: 0;
}

.sidebarmenu li {
  float: left;
  width: 100%;
  margin: 15px 0;
  /* padding: 12px 25px; */
  position: relative;
  border-radius: 6px;
  font-size: 20px;
  line-height: 25px;
  color: #374151;
  font-weight: 400;
  font-family: var(--kg-primary-font);
  /* border: 2px solid transparent; */
}

.sidebarmenu li a {
  color: #374151;
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  float: left;

  svg {
    stop {
      stop-color: #1f2937;
    }
  }
}

.sidebarmenu.mobile li a svg,
.sidebarmenu.mobile li button svg {
  margin-right: 10px;
}

.sidebarmenu li:hover a,
.sidebarmenu li.active a {
  background: var(--linear-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  svg {
    stop:first-child {
      stop-color: var(--kg-secondary);
    }
    stop:last-child {
      stop-color: var(--kg-primary);
    }
  }
}

.sidebarmenu li.active a {
  font-weight: 500;
}

.sidebarmenu li a > span,
.sidebarmenu li button > span {
  margin-right: 14px !important;
}

.sidebarmenu li a > span:nth-child(2),
.sidebarmenu li button > span:nth-child(2) {
  display: none !important;
}

// .sidebarmenu li:hover a > span:nth-child(1),
// .sidebarmenu li.active a > span:nth-child(1),
// .sidebarmenu li:hover button > span:nth-child(1),
// .sidebarmenu li.active button > span:nth-child(1) {
//   display: none !important;
// }

.sidebarmenu li:hover a > span:nth-child(2),
.sidebarmenu li.active a > span:nth-child(2),
.sidebarmenu li:hover button > span:nth-child(2),
.sidebarmenu li.active button > span:nth-child(2) {
  display: block !important;
}

.sidebarmenu li button {
  padding: 0;
  background: transparent !important;
  font-size: 18px;
  line-height: 24px;
  font-family: var(--kg-primary-font);
  letter-spacing: 0px;
  color: #374151 !important;
  border: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sidebarmenu li.active button,
.sidebarmenu li:hover button {
  background: var(--linear-gradient) !important;
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

.sidebarmenu.mobile {
  display: none;
}

.newDesignMenu > button > svg {
  width: 24px;
  height: 24px;
  margin-right: 14px;
}

.newDesignMenu > button > svg > g > path {
  fill: #374151 !important;
}

.newDesignMenu:hover > button > svg > g > path {
  fill: url(#paint0_linear_3262_9971) !important;
}

.usermenuouter.mobile {
  display: none;
  margin: 0;
}

.mobilemenutoggle.btn.btn-primary {
  background: transparent !important;
  padding: 0;
  border: 0px;
  color: var(--kg-primary);
  line-height: 20px;
  display: none;
  margin-right: 5px;
}

.mobilemenutoggle.btn.btn-primary svg {
  float: left;
}

#mobilemenuoverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: none;
  background: #6f6c6c32;
}

.user_option .usermenuouter.mobile .usermenu.btn {
  border-radius: inherit;
  padding: 0;
  height: auto;
  width: auto;
  position: relative;
  z-index: 999;
  text-transform: capitalize;
  background: transparent !important;
  color: #000;
  text-align: left;
  display: flex;
  align-items: center;
  border-color: #fff;
}

.user_option .usermenuouter.mobile .usermenu.btn span {
  background: #5c5c5c;
  color: #fff;
  height: 50px;
  width: 50px;
  min-width: 50px;
  float: left;
  line-height: 50px;
  font-size: 25px;
  text-align: center;
  border-radius: 50%;
  margin: 0 10px 0 0;
  text-transform: uppercase;
}

.user_option .usermenuouter.mobile .usermenu.btn svg {
  margin-left: 5px;
}

.usermenuouter.mobile .usermenuhover {
  left: -4px;
  right: auto;
  padding: 60px 23px 7px;
}

.usermenuouter.mobile .usermenuhover::before {
  right: auto;
  left: 0;
  top: -4px;
  width: 58px;
  height: 58px;
}

.usermenuouter.mobile .usermenuhover::after {
  border-radius: 0px 6px 6px 6px;
  top: 54px;
}

#mobilemenuclose svg {
  font-size: 20px;
  line-height: 20px;
  float: left;
}

#mobilemenuclose {
  top: 15px;
  right: 15px;
  position: absolute;
  z-index: 9;
  cursor: pointer;
  display: none;
}

@media (max-width: 1130px) {
  .sidebarmenu li,
  .sidebarmenu li button {
    font-size: 15px;
  }
}

@media (max-width: 991px) {
  .pgsidebar {
    width: 100%;
    padding: 0;
    border: 0;
    margin: 0;
  }

  .pgcontent {
    width: 100%;
    padding-left: 0;
  }

  .sidebarmenu {
    display: none;
  }

  .sidebarmenu.open {
    display: table;
  }

  .sidebarmenu.mobile {
    display: block;
    margin: 0;
    padding: 20px 0;
    border-bottom: 1px solid #e5e5e5;
  }
}

@media (max-width: 790px) {
  .pgcontentinner {
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media (max-width: 450px) {
  .pgcontentinner {
    padding-right: 10px;
    padding-left: 10px;
  }
}
