.tokgpro {
  position: relative;
  z-index: 99;
  margin-right: 27px;
  border: 0;
  font-size: 14px;
  line-height: 21px;
  font-family: var(--kg-primary-font);
  letter-spacing: 0px;
  color: #ffffff !important;
  border-radius: 8px;
  padding: 8px 17px;
  font-weight: 600;
  display: flex;
  align-items: flex-start;
  float: left;

  &::before {
    position: absolute;
    background:
      linear-gradient(white, white) padding-box,
      linear-gradient(to right, var(--kg-secondary), var(--kg-primary))
        border-box;
    border: 2px solid transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    border-radius: 8px;
  }

  &:hover {
    &::before {
      background:
        linear-gradient(transparent, transparent) padding-box,
        linear-gradient(to right, var(--kg-secondary), var(--kg-primary))
          border-box;
    }
    label {
      background: #fff !important;
      background-clip: text !important;

      > span:nth-child(1) {
        display: block !important;
      }

      > span:nth-child(2) {
        display: none !important;
      }
    }
  }

  label {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    background: var(--linear-gradient) !important;
    background-clip: text !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;

    > span {
      margin-right: 7px !important;
      display: none !important;

      &:nth-child(2) {
        display: block !important;
      }
    }
  }
}
