.mobile-popuptext {
  text-align: center;
  font-size: 14px;
  padding: 0 0 15px;
  color: #000;
  font-family: var(--kg-primary-font);
  display: table;
  margin: auto;
  line-height: 24px;
}

.chat-modal h4 {
  font-size: 24px;
  font-weight: 500;
  color: #252424;
  text-align: center;
}
