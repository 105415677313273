html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--kg-primary-font);
  text-rendering: optimizeSpeed;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.28581;
  text-transform: none;
}

#__next {
  overflow: hidden;
  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none !important;
}

button {
  /* Reset size */
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;

  /* Reset background and border */
  background: none;
  border: none;
  box-shadow: none;

  /* Optional: reset outline on focus */
  outline: none;
}

a:focus,
*,
*::before,
*::after {
  outline: none !important;
  line-height: 1.28581;
}

* {
  box-sizing: border-box;
}

html {
  min-height: -webkit-fill-available;
}

body {
  padding: 0;
  margin: 0;
}

#root {
  width: 100vw;
  height: 100%;
}

.bp5-dark #root {
  background-color: #30404d;
}

.container {
  max-width: 1450px !important;
  --bs-gutter-x: clamp(1.5rem, 6%, 4.5rem) !important;
}

*,
*::before,
*::after {
  font-family: var(--kg-primary-font);
}

body {
  overflow-x: hidden;
}

.main {
  background-color: #fafafa;
  min-height: 100dvh;
}

button i {
  color: #000;
}

@media (max-width: 520px) {
  .container {
    --bs-gutter-x: 1.5rem !important;
  }
}
