@use '../../utilities/mixins' as mixins;

.shluchimbtn {
  width: 100%;
  float: left;
  margin: 15px 0 0;
  span {
    float: left;
    width: 100% !important;
    position: unset !important;
  }
  img {
    position: relative !important;
    height: unset !important;
  }
}

.purple-link-button,
.purple-link-text {
  @include mixins.gradientText;
  font-size: 14px;
  font-weight: 400;
  border: 0px !important;
  padding-bottom: 5px;
}

.purple-link-text {
  font-weight: 500;
}

.purple-link-button:hover {
  color: #6b7280;
  -webkit-text-fill-color: #6b7280;
  background-clip: unset;
  -webkit-background-clip: unset;
  background: none;
}

.downloadbtn > .bp5-button-text {
  display: inline-flex !important;
  gap: 4px;
  align-items: center;
}

.btn-hover {
  border: transparent;
  transition: 0.3s ease-in-out;
}

.btn-hover:active {
  transform: scale(0.9);
}

.home-button-show,
.file-button {
  display: flex;
  border: none;
  outline: transparent;
  background: transparent;
  font-size: 16px;
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding: 8px 15px;
  margin-left: 5px;
  margin-right: 5px;
  color: #fff;
  border-radius: 5px;
  transition: 0.3s;
  text-transform: capitalize;
  font-family: var(--kg-primary-font);
}

.home-button-show svg path {
  fill: #fff;
}

.home-button-show:hover,
.file-button:hover,
.file-button.active,
.home-button-show.active {
  filter: drop-shadow(0 0 0.75rem var(--kg-primary));
  background-color: hsla(0, 0%, 100%, 0.07);
}

.file-button,
.home-button-show.resize {
  margin-left: 0;
  margin-right: 5px;
}

.home-button-show.resize svg {
  width: 23px;
  height: 23px;
}

.purple-button,
.purple-button span {
  @include mixins.gradientText;
  transition: 0.2s;
}

.purple-button:hover,
.purple-button span:hover {
  color: #6b7280 !important;
  background: unset;
  background-clip: unset;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
}

.grey-link {
  color: #6b7280;
  background: unset;
  background-clip: unset;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  transition: 0.2s;

  &:hover {
    @include mixins.gradientText;
  }
}
.powered-link {
  margin-right: 10px;
}

@media (max-width: 991px) {
  .home-button-show {
    display: none;
  }
  .home-button-show.resize {
    display: flex;
    margin-right: 5px;
    padding: 8px 10px;
  }
  .home-button-show.resize .home-button-text {
    display: none;
  }
  .file-button {
    margin-right: 5px;
  }
}

@media (max-width: 767px) {
  .file-button {
    padding: 8px 10px;
  }
}
