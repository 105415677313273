.master-shape-effects-container
  .polotno-panel-container
  > div
  > label:is(
    :nth-of-type(1),
    :nth-of-type(2),
    :nth-of-type(3),
    :nth-of-type(4)
  ) {
  display: none;
}

.master-shape-toolbar-container .go3144864827 > div {
  vertical-align: middle;
}

.master-shape-toolbar-container .go3144864827 > div > button {
  order: 0;
  vertical-align: middle;
}

.master-shape-toolbar-container .go3144864827 > div > span:last-of-type {
  order: 1 !important;
  vertical-align: middle;
}

.master-shape-toolbar-container .go3144864827 > div > .bp5-popover-target {
  order: 2;
  vertical-align: middle;
}

.master-shape-toolbar-container .go3144864827 > div > span:first-of-type {
  order: 3 !important;
  margin-left: 5.5px;
  vertical-align: middle;
}

.master-shape-toolbar-container .go3144864827 > div > .bp5-popover-target > div {
  margin-bottom: -2px !important;
}