@keyframes blink {
  0%,
  20%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loadingBounce {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-0.5rem);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes loadingBounce {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-0.5rem);
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes minimize {
  0% {
    clip-path: ellipse(100vw 100vw at 50% 50%);
  }

  100% {
    clip-path: ellipse(0px 0px at 90% 90%);
  }
}

@keyframes loading {
  0% {
    left: -45%;
  }
  100% {
    left: 100%;
  }
}

@keyframes show-background {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes skeleton-glow {
  0% {
    background: rgba(211, 216, 222, 0.2);
    border-color: rgba(211, 216, 222, 0.2);
  }
  100% {
    background: rgba(95, 107, 124, 0.2);
    border-color: rgba(95, 107, 124, 0.2);
  }
}
