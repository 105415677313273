.auth-popup.deletedesign-popup h4 {
  font-size: 25px;
  font-size: clamp(1.125rem, 0.9063rem + 0.7292vw, 1.5625rem);
  margin: 0 0 30px;
}

.deletedesign-popup .modal-body {
  text-align: center;
}

.deletedesign-popup .modal-body .buttons-container {
  float: left;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
