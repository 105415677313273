.home-slider-block .slick-slider {
  margin: 0px auto;
  max-width: 1000px;
  width: 100%;
}

.sub-cat-slider-block .slick-slider {
  margin: 0px auto;
  max-width: 100%;
  width: 100%;
}

.sub-cat-slider-block {
  float: left;
  width: 100%;
  padding: 30px 10px 50px;
  text-align: center;
  border-radius: 0;
  margin: 0;
  position: relative;
  background: transparent;
}

.sub-cat-slider-block.disable-slide {
  display: none;
}

.slick-dots li button:before {
  color: var(--kg-light-primary) !important;
}

.slick-dots li.slick-active button:before {
  color: var(--kg-primary) !important;
}

.template-single {
  display: table;
  margin: auto;
  background: #fff;
  padding: 0px;
  border-radius: 8px;
}

.userhomepgcontent .template-single {
  background: #fafafa;
}

.template-single-image {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0px auto 5px;
  position: relative;
  border-radius: 36px;
  padding: 5px;
}

.template-single-image div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.template-single-image > div > div {
  padding: 5px;
}

.template-single h4 {
  color: #5c5c5c;
  font-family: var(--kg-primary-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.template-single:hover .template-single-image {
  background: rgba(var(--kg-secondary-rgb), 0.1);
}

.template-single:hover h4 {
  font-weight: 600;
}

.category-section {
  max-width: 840px;
  margin: 45px auto;
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 9px));
  gap: 12px;
  padding: 0px clamp(0px, 1vw, 10px);
}

.category-section .category-block {
  border: 1px solid var(--kg-primary);
  border-radius: 16px;
  padding: clamp(12px, 3vw, 24px) 0 clamp(12px, 3vw, 24px) 0;
  cursor: pointer;
}

.category-section .category-block:hover {
  background: rgba(var(--kg-secondary-rgb), 0.1);
}

.category-section
  .category-block
  .category-icon
  .category-svg
  > *:not(:first-child) {
  display: none;
}

.category-section .category-block .category-svg,
.category-section .category-block .category-svg-loading {
  margin: auto;
  margin-bottom: clamp(10px, 2.2vw, 20px);
  width: min-content;
}

.category-section .category-block .category-svg > div {
  width: clamp(30px, 5vw, 50px);
  height: clamp(30px, 5vw, 50px);
}

.category-section .category-block .category-svg .bp5-skeleton {
  border-radius: 50%;
}

.category-section .category-block svg {
  width: 100%;
  height: 100%;
  margin-top: 0px;
}

.category-section .category-block span {
  font-size: clamp(11px, 1.5vw, 14px);
}

.category-section .custom-block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-section .custom-block > svg {
  width: 16px;
  height: 16px;
  margin: 0;
}

@media (max-width: 480px) {
  .sub-cat-slider-block.disable-slide {
    display: block;
  }

  .category-section {
    padding: 0;
  }
}

@media (max-width: 580px) {
  .category-section {
    grid-template-columns: repeat(3, calc(33% - 7px));
  }
}