.pretextbtns,
.pretextbtns2 {
  margin-bottom: 8px;
  width: 100%;
  font-size: 25px;
  font-family: var(--kg-secondary-font);
  background: var(--polotno-elements-bg);
  border: 0px;
  text-align: left;
  border-radius: 5px;
  min-height: 35px;
  padding: 10px 15px;
  color: var(--editor-text);
}
.pretextbtns2 {
  font-size: 14px;
}
.pretextbtns {
  font-weight: 500;
}

.textpanel-btns + div {
  height: calc(100% - 80px);
}

.textpanel-btns p {
  margin: 0 !important;
  color: var(--elements-light-text);
}

.customtextbtns {
  margin: 10px 0 0;
  width: 100%;
  font-size: 17px;
  border-radius: 5px;
  padding: 8px 10px;
  font-family: var(--kg-secondary-font);
  background: #efefef;
  border: 2px solid #dbdbdb;
}

.customtext-list {
  display: flex;
  justify-content: space-between;
  max-height: 100%;
  overflow: auto;
  padding: 10px;
  flex-wrap: wrap;
}

.customtext-listsingle {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  width: 48%;
  box-shadow: 0px 0px 2px #828282;
  background: rgba(143, 153, 168, 0.05);
  padding: 10px;
  font-size: 15px;
  font-family: var(--kg-secondary-font);
  text-align: center;
  justify-content: center;
  float: left;
  margin: 10px 0;
  cursor: pointer;
}

.resizeblock {
  width: 100%;
  padding: 10px 2px;
  max-height: 100%;
  overflow: auto;
}

.resizebtn-group {
  width: 100%;
  padding: 10px 0 0;
}

.resizebtn-single {
  width: 100%;
  padding: 9px 10px;
  font-size: 16px;
  box-shadow: 0px 0px 2px #828282;
  color: #1c2127;
  border: 0px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 4px;
  margin: 10px 0 0;
  background: rgba(143, 153, 168, 0.05);
}

.resizebtn-single span {
  font-size: 0.7rem;
  padding-left: 20px;
  float: right;
  margin-left: auto;
}

.fonts-group {
  width: 100%;
  padding: 10px 2px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-height: 100%;
  overflow: auto;
}

.font-single {
  width: 48%;
  margin: 10px 0 0;
  padding: 10px;
  font-size: 14px;
  background: rgba(143, 153, 168, 0.05);
  box-shadow: 0px 0px 2px #828282;
  color: #959595;
  border: 0px;
  flex-wrap: wrap;
  border-radius: 4px;
  word-wrap: break-word;
}

.resizebtn-single:hover,
.font-single:hover {
  background: rgba(143, 153, 168, 0.15);
}

.previewurl {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 440px;
  padding: 10px;
  border: 1px dashed #ccc;
  border-radius: 3px;
  margin-top: 10px;
  background: #eee;
  margin-bottom: 0px;
  position: relative;
  cursor: copy;
  padding-right: 30px;
}

.previewurl svg {
  align-items: center;
  width: 23px;
  height: 23px;
  position: absolute;
  top: 10px;
  right: 5px;
  fill: var(--linear-gradient);
  cursor: copy;
}

.resizeblock-magicresize {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-right: 5px;
  color: #fff;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin-left: auto;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #d938b9;
}

input:focus + .slider {
  box-shadow: 0 0 1px #d938b9;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 20px;
}
