.zman-button-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}

.zman-button {
  font-size: 0.8rem;
  font-family: var(--kg-secondary-font);
  background: var(--polotno-elements-bg);
  border: 0px;
  border-radius: 5px;
  padding: 7px 15px;
  flex-grow: 1;
  text-align: center;
  color: var(--editor-text);
}

.zman-button.selected {
  border: 2px solid var(--editor-text);
}

.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: #989292 !important;
}

.side-panel-error {
  padding: 10px;
  text-align: center;
  color: red;
}

.zman-widget-container {
  height: 100%;
  padding-right: 5px;
  padding-bottom: 75px;
  overflow-y: auto;
}

.zman-calendar {
  border: 0.5px solid #978f8f7e;
  border-radius: 7px;
}

.zipcode_text {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--editor-text);
}

.zipcode_text svg path {
  fill: var(--editor-text);
}

.zman-outlined-button {
  display: flex;
  align-items: center;
}

.rdp-day_selected {
  background: var(--kg-primary) !important;
}

.rdp-caption_label {
  font-weight: 500 !important;
}

.rdp-head .rdp-head_row th {
  font-weight: 500 !important;
}
