.tos-popup p {
  text-align: left;
  font-size: 13px;
}

.tos-popup p a {
  font-weight: 600;
}

.tos-popup .tos-checkbox {
  color: black;
}

.tos-popup .tos-continue-btn {
  width: 100%;
  padding: 16px;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
  line-height: 12px;
  background: var(--linear-gradient);
  border-radius: 5px;
  box-shadow: none !important;
  border: 0;
}

.tos-popup .tos-continue-btn:disabled {
  background: lightgray;
  color: black;
}

.tos-popup .tos-continue-btn-enable {
  color: white !important;
}

.tos-popup .tos-close-btn {
  width: 16px;
  position: absolute;
  right: 20px;
}

.tos-popup .tos-close-btn svg {
  stroke: #6b7280;
  stroke-width: 7rem;
}

.account-suspension-modal p a {
  font-weight: 600;
  text-decoration: underline;
}
