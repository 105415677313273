.loading-dot-container {
  display: inline-block;
}

.loading-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 2px;
  background-color: black;
  border-radius: 50%;
  opacity: 0;
  animation: blink 1.4s infinite both;
  background: var(--linear-gradient);
  margin: 0 5px;
}

.loading-dot:nth-child(1) {
  animation-delay: 0s;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.4s;
}

.verification-pg {
  text-align: center;
}

.verification-pg h2 {
  text-align: center;
  display: inline !important;
  margin-right: 5px;
  font-size: 40px !important;
}

.verification-pg p {
  text-align: center;
  font-size: 26px !important;
  margin-top: 20px;
}

.verification-pg .animated-dots {
  font-size: 60px !important;
}
