.navbar-expand-lg .navbar-collapse {
  justify-content: right;
}

/* Header Css*/

header {
  box-shadow: 0px 2px 6px #9e9e9e52;
  /* position: relative; */
}

header .navbar-expand-lg {
  padding: 15px 0;
}

header .navbar-brand {
  padding: 0;
  float: left;
  margin: 0 25px 0 0;
}

header .navbar-brand span {
  float: left;
}

header .navbar-toggler {
  border: 0px !important;
  box-shadow: none !important;
}

header .navbar-nav > .nav-item {
  padding: 0 11px;
  position: relative;
  margin-right: 10px;
  border-radius: 6px;
  transition: background 0.3s;
}

header .navbar-nav > .nav-item:hover {
  background: #fff;
}

header .navbar-nav > .nav-item:hover .nav-link {
  background: var(--linear-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

header .navbar-nav > .has-children.nav-item {
  padding-right: 25px;
}

header .nav-item.dropdown {
  position: absolute;
  left: 0;
  top: 0;
  text-align: right;
  width: 100%;
}

header .navbar-expand-lg .navbar-nav .nav-link,
header .navbar-expand-lg .navbar-nav .nav-link.active {
  padding: 0;
  display: block;
  line-height: 32px;
  position: relative;
  z-index: 9;
  text-decoration: none;
  color: #000;
  font-family: var(--kg-primary-font);
  font-weight: 500;
}

header .navbar-expand-lg .navbar-nav .dropdown-toggle.nav-link {
  margin-right: 10px;
  height: 32px;
  z-index: 1;
}

header .dropdown-toggle::after {
  display: inline-block;
  margin: 0;
  vertical-align: unset;
  content: '\f107';
  font-family: FontAwesome;
  border: 0;
}

header .dropdown-menu {
  margin-top: 0 !important;
  background: transparent;
  border: 0px;
  padding: 20px 10px;
  position: relative !important;
  z-index: 99999;
}

header .dropdown-item {
  font-size: 14px;
  line-height: 20px;
  color: #0d1216 !important;
  z-index: 9;
  position: relative;
  padding: 8px 10px;
  background: #ffffff !important;
  white-space: inherit !important;
  border-radius: 5px;
  transition: background-color 0.3s;
}

header .dropdown-item:hover {
  background: var(--hover-gradient) !important;
}

header .dropdown-menu::before {
  background: #ffffff;
  position: absolute;
  top: 10px;
  bottom: 10px;
  z-index: 9;
  content: '';
  width: 100%;
  left: 0;
  border-radius: 6px;
  box-shadow:
    0 0 0 1px rgba(64, 87, 109, 0.07),
    0 2px 12px rgba(53, 71, 90, 0.2);
}

.user_option {
  float: right;
  margin-left: 10px;
  position: relative;
  order: 2;
}

.user_option .btn-hover-outer {
  position: relative;
  margin-left: 15px;
  float: left;
}

.user_option .btn-hover-outer .btn {
  background: var(--linear-gradient);
  font-size: 14px;
  line-height: 21px;
  font-family: var(--kg-primary-font);
  letter-spacing: 0px;
  color: #ffffff;
  border: 0px;
  border-radius: 6px;
  padding: 8px 15px;
  text-transform: uppercase;
  font-weight: 600;
}

.usermenuouter .usermenuinner > .btn {
  background: #5c5c5c !important;
  font-size: 16px;
  line-height: 21px;
  font-family: var(--kg-primary-font);
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: 15px;
  border: 0px;
  border-radius: 6px;
  padding: 8px 15px;
  text-transform: uppercase;
  font-weight: 400;
}

.user_option .btn-hover-outer .btn:active {
  transform: scale(0.95);
}

.user_option .btn-hover-outer .btn:hover {
  background: var(--kg-primary) !important;
  -webkit-text-fill-color: inherit;
}

.usermenuouter {
  margin-left: 15px;
  display: inline-block;
  position: relative;
}

.usermenuinner {
  display: inline;
}

.usermenuouter .usermenuinner > .btn {
  margin-left: 0;
}

.user_option .usermenu.btn {
  border-radius: 50%;
  padding: 0;
  height: 37px;
  width: 37px;
  position: relative;
  z-index: 999;
  text-transform: uppercase;
}

.usermenuhover {
  position: absolute;
  right: -4px;
  margin: 0;
  list-style: none;
  background: transparent;
  top: 0;
  padding: 48px 10px 10px;
  display: none;
  flex-direction: column;
  min-width: 130px;
}

.usermenuhover::before {
  position: absolute;
  right: 0;
  background: #fff;
  top: -4px;
  width: 45px;
  content: '';
  height: 45px;
  border-radius: 50% 50% 0 0;
  z-index: 120;
  -webkit-clip-path: inset(-5px -5px 0px -5px);
  clip-path: inset(-5px -5px 0px -5px);
  box-shadow:
    0 0 0 1px rgba(64, 87, 109, 0.07),
    0 -2px 12px rgba(53, 71, 90, 0.2);
}

.usermenuhover::after {
  position: absolute;
  left: 0;
  background: #fff;
  border-radius: 6px 0px 6px 6px;
  width: 100%;
  top: 40px;
  content: '';
  bottom: 0;
  z-index: 99;
  box-shadow:
    0 0 0 1px rgba(64, 87, 109, 0.07),
    0 2px 12px rgba(53, 71, 90, 0.2);
}

.usermenuhover li a,
.usermenuhover li button {
  width: 100%;
  display: block;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  color: #4b5563;
  padding: 8px 10px;
  position: relative;
  z-index: 999;
  text-decoration: none;
  text-align: left;
  transition: 0.2s;
}

.usermenuhover li a:hover,
.usermenuhover li button:hover {
  background: var(--hover-gradient);
}

.usermenuhover li a:hover,
.usermenuhover li button:hover {
  transition: 0.1s;
}

.usermenuinner:hover .usermenuhover {
  display: flex;
}

.header-logo {
  width: 140px;
  height: 55px;
  position: relative;
}

.header-logo span {
  width: 100%;
}

.header-logo-image {
  width: 100%;
  object-fit: contain;
}

@media (max-width: 1300px) {
  header .navbar-nav > .nav-item {
    margin-right: 5px;
  }

  .user_option {
    margin-left: 0;
  }
}

@media (max-width: 1200px) {
  .user_option .btn-hover-outer .btn {
    padding: 8px 10px;
  }
}

@media (max-width: 1100px) {
  .user_option .btn-hover-outer {
    margin-left: 10px;
  }

  .user_option .btn-hover-outer .btn {
    font-size: 12px;
  }

  header .navbar-expand-lg .navbar-nav .nav-link,
  .dropdown-item {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  header .nav-item:hover > .nav-item.dropdown .dropdown-menu {
    display: block;
  }
}

@media (max-width: 991px) {
  header .navbar-expand-lg .navbar-nav .nav-link {
    font-size: 15px;
  }

  .navbar-nav {
    margin: 20px 0;
    width: 100%;
  }

  header .navbar-nav > .nav-item {
    padding: 0;
    position: relative;
    margin-right: 0;
  }

  header .navbar-nav > .nav-item:hover {
    background: transparent;
  }

  header .navbar-expand-lg {
    position: inherit;
  }

  #basic-navbar-nav.open {
    right: 0;
  }

  #navbarScroll.open {
    right: 0;
  }

  #mobilemenuoverlay.open,
  .mobilemenutoggle.btn.btn-primary {
    display: flex !important;
  }

  .navbar-nav {
    float: left;
  }

  .user_option {
    margin-left: 0;
    width: 100%;
  }

  .user_option .btn-hover-outer {
    margin: 5px 0;
    width: 100%;
    text-align: center;
  }

  .user_option .btn-hover-outer button {
    font-size: 14px;
    margin: 0;
    width: 100%;
  }

  header .navbar-nav > .has-children.nav-item {
    margin: 5px 0;
    transition: background 0.2s linear;
  }

  header .navbar-expand-lg .navbar-nav .nav-link,
  header .navbar-expand-lg .navbar-nav .nav-link.active {
    border-radius: 5px;
    padding: 0 10px;
    line-height: 35px;
    margin: 0 !important;
  }

  header .navbar-expand-lg .navbar-nav > .nav-item > .nav-link:hover {
    background: #40576d12;
  }

  #mobilemenuclose {
    display: block;
  }

  header .navbar-nav > .has-children.nav-item {
    padding: unset;
  }

  .usermenuouter.desktop {
    display: none;
  }

  .usermenuouter.mobile {
    margin: 0;
    display: flex;
    align-items: center;
    float: left;
  }

  .collapse:not(.show) {
    display: block;
    position: fixed;
    right: -260px;
    background: #fff;
    top: 0;
    padding: 40px 20px;
    width: 260px;
    transition: all 0.25s;
    z-index: 99999;
    height: 100vh;
    overflow-y: auto;
    box-shadow: 0px 0px 6px #eaeaea;
  }

  .category-skeleton {
    margin: 5px 0;
  }
}

@media (max-width: 580px) {
  .user_option {
    float: left;
    margin-left: 0;
  }
}
