.view-demo-page h1 {
  font-size: clamp(32px, 4vw, 60px);
  line-height: clamp(35px, 4vw, 66px);
  font-weight: 700;
}

.view-demo-page div {
  margin-bottom: 40px;
}

.view-demo-page div:first-of-type a {
  color: #0066ff;
  text-decoration: underline;
}

.view-demo-page div:first-of-type span {
  font-weight: 600;
}

.view-demo-page div:first-of-type a,
.view-demo-page div:first-of-type span {
  font-size: clamp(16px, 1.8vw, 24px);
  line-height: clamp(24px, 1.8vw, 36px);
}

.view-demo-page .btn-hover-outer {
  margin-top: 40px;
  text-align: right;
}

.view-demo-page .button-contained {
  padding: 12px 24px;
  font-size: 16px;
}

.view-demo-page .button-contained > * {
  vertical-align: middle;
}

.view-demo-page .button-contained > span:nth-of-type(2) {
  margin-bottom: 2px;
  stroke: white;
  stroke-width: 0.5px;
  margin-left: 5px;
}

@media (max-width: 991px) {
  .view-demo-page .btn-hover-outer {
    text-align: center;
  }
}

.demo-sign-up-page {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}
