@mixin addGradientBackground() {
  box-sizing: border-box;
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 20px;
  background-size: 300% 100%;
  border: 1px solid transparent;
  background: linear-gradient(
      90.57deg,
      var(--kg-secondary) -5.35%,
      var(--kg-primary) 88%
    )
    border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  transition: 0.3s;
}

@mixin gradientText() {
  background: linear-gradient(
    91deg,
    var(--kg-secondary) -5.35%,
    var(--kg-primary) 88%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
