@use '../../utilities/mixins' as mixins;

.userhomepgcontent {
  padding: 50px 10px;
}

%section-h4 {
  font-size: 64px;
  line-height: 74px;
  font-family: var(--kg-primary-font);
  color: #000;
  margin: 0 0 15px;
  font-weight: 600;
}

%section-sub-text {
  font-size: 26px;
  line-height: 36px;
  font-family: var(--kg-primary-font);
  color: #000;
  float: left;
}

.magicautofill-block {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  display: table;
  width: 100%;
  padding: 20px 0 50px;
}

.magicautofill-blockinner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  width: 92%;
  margin: 0 auto;
}

.magicautofill-block-left {
  float: left;
  width: 52%;
}

.magicautofill-block-left h4 {
  @extend %section-h4;
  display: flex;
  align-items: center;
  float: left;
}

.magicautofill-block-left h4 .gradient-text {
  display: inline-block;
  margin: 0 0 0 13px;
}

.magicautofill-block-left h4 .black-pill {
  font-size: 11px;
  line-height: 12px;
  font-family: var(--kg-primary-font);
  color: #fff;
  background-color: #000;
  margin: 0 0 0 13px;
  padding: 6px 14px;
  font-weight: 500;
  border-radius: 50px;
  text-transform: uppercase;
}

.magicautofill-block-left p {
  @extend %section-sub-text;
}

.magicautofill-block-right {
  float: left;
  margin-left: auto;
  width: 38%;
}

.magicautofill-block-right img {
  border-radius: 8px;
  border: 1px solid #e011cb !important;
}

.manydesignlayouts-block {
  float: left;
  width: 100%;
}

.manydesignlayouts-blockinner {
  position: relative;
  display: flex;
  border-radius: 16px;
  background: #fff;
  align-items: center;
  justify-content: space-between;

  > span {
    position: absolute !important;
    width: auto !important;
    left: 0;
    top: 0;
    bottom: -100px;
  }
}

.manydesignlayouts-left {
  width: 50%;
  position: relative;

  .manydesignlayouts-mobileimg {
    display: none;
  }

  .manydesigns-img-container {
    width: 90%;
    position: relative;
    z-index: 100;

    &::before,
    &::after {
      position: absolute;
      content: '';
      background-image: url('/images/homepage/waves.png');
      background-size: auto;
      background-repeat: no-repeat;
      background-position: right;
      height: 22px;
    }

    &::before {
      left: -70px;
      top: 30px;
      width: 100px;
    }

    &::after {
      right: -130px;
      bottom: 70px;
      width: 150px;
    }
  }
}

.manydesignlayouts-right {
  width: 50%;
  padding: 10px;

  h4 {
    @extend %section-h4;
  }

  p {
    @extend %section-sub-text;
  }
}

.cleankosher-block {
  float: left;
  width: 100%;
  margin: 150px 0 250px 0;
}

.cleankosher-blockinner {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 70px 260px 70px 50px;
  border-radius: 24px;
  background: #fff;

  .col-md-12 {
    position: relative;
    margin: auto;
    display: table;
    width: 92%;

    &::before {
      opacity: 0.3;
      background: radial-gradient(
        71.4% 71.4% at 61.95% 29.61%,
        var(--kg-secondary) 0%,
        var(--kg-primary) 100%
      );
      width: 144px;
      height: 144px;
      position: absolute;
      content: '';
      bottom: -90px;
      left: -30px;
      border-radius: 50%;
    }
  }

  &::before,
  &::after {
    position: absolute;
    background-image: url('/images/homepage/waves.png');
    background-size: auto;
    background-repeat: no-repeat;
    content: '';
    height: 22px;
  }
  &::before {
    right: -20px;
    left: 0;
    top: -40px;
    background-position: right;
  }
  &::after {
    background-position: left;
    right: 0;
    left: 60px;
    top: auto;
    bottom: -90px;
  }

  > span {
    position: absolute !important;
    width: auto !important;
    right: 35px;
    top: 40px;
    bottom: 40px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  img {
    -o-object-fit: contain;
    object-fit: contain;
    object-position: right;
  }

  h4 {
    @extend %section-h4;
  }

  p {
    @extend %section-sub-text;
  }
}

.kosherai-block {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  display: table;
  width: 100%;
  padding: 70px 0 100px;

  .kosherai-blockinner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    width: 92%;
    margin: 0 auto;
  }
}

.kosherai-block-left {
  float: left;
  width: 48%;
  position: relative;
  padding: 34px 28px 15px 32px;

  img {
    border-radius: 8px;
    border: 1px solid #e011cb !important;
    z-index: 9;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-size: auto;
    background-repeat: no-repeat;
  }

  &::before {
    left: 0;
    top: 0;
    width: 113px;
    height: 119px;
    background-image: url('/images/homepage/aiblock-img1.svg');
    background-position: right;
  }

  &::after {
    right: 0;
    bottom: 0;
    width: 162px;
    height: 145px;
    background-image: url('/images/homepage/aiblock-img2.svg');
  }
}

.kosherai-block-right {
  float: left;
  width: 45%;
  margin-left: auto;
  order: 2;

  h4 {
    @extend %section-h4;
    align-items: center;
    display: flex;

    > span {
      margin: 0 15px 0 0 !important;
    }

    .gradient-text {
      margin: 0 0 0 13px;
    }
  }

  p {
    @extend %section-sub-text;
    float: left;
  }
}

.partners-block {
  background: #fafafa;
}

.partners-block .partners-text-container {
  background: var(--linear-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.partners-block .text-container h4 {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
  text-decoration-skip-ink: none;
}

.partners-block .image-container {
  position: relative;
  z-index: 2;
  border: 2px solid #e5e7eb;
  padding: 24px 40px 24px 40px;
  border-radius: 24px;
  background: white;
}

.partners-block .partners-container {
  position: relative;
}

.partners-block .partners-left-icons-container > div {
  position: absolute;
}

.partners-block .partners-left-icons-container > div:first-of-type {
  bottom: -60px;
  left: -40px;
}

.partners-block .partners-left-icons-container > div:last-of-type {
  bottom: -45px;
  left: 50px;
  transform: rotate(180deg);
}

.partners-block .partners-right-icons-container > div {
  position: absolute;
  right: 0;
  top: -30px;
  transform: rotate(180deg);
}

.partners-block .partners-card {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.partners-block .card-image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 141.54px;
  width: calc((100% / 7) - 13.72px);
}

@media (max-width: 1200px) {
  .partners-block .card-image {
    height: 126px;
    width: calc((100% / 6) - 13.34px);
  }
}

@media (max-width: 1080px) {
  .partners-block .partners-right-icons-container {
    display: none;
  }
}

@media (max-width: 991px) {
  .partners-block .card-image {
    height: 120px;
    width: calc((100% / 5) - 12.8px);
  }
}

@media (max-width: 767px) {
  .partners-block .card-image {
    height: 112px;
    width: calc((100% / 4) - 12px);
  }
}

@media (max-width: 520px) {
  .partners-block .card-image {
    height: 91px;
    width: calc((100% / 3) - 10.67px);
  }
}

@media (max-width: 380px) {
  .partners-block .card-image {
    height: 78px;
    width: calc((100% / 2) - 8px);
  }
}

@media (max-width: 1200px) {
  %section-h4 {
    font-size: 45px;
    line-height: 50px;
  }

  %section-sub-text {
    font-size: 17px;
    line-height: 27px;
  }
}

@media (max-width: 991px) {
  %section-h4 {
    font-size: 36px;
    line-height: 41px;
  }

  .kosherai-block-left {
    padding: 20px 20px 15px 25px;
  }
  .p-100,
  .pg-100,
  .userhomepgcontent {
    padding: 50px 20px;
  }

  .partners-block .section-container {
    padding: 100px 50px;
  }
}

@media (max-width: 767px) {
  %section-sub-text {
    font-size: 17px;
    line-height: 27px;
  }

  .magicautofill-block-left {
    width: 100%;
  }

  .magicautofill-block-right {
    margin: 30px 0 0;
    width: 100%;
    text-align: center;
  }

  .magicautofill-block {
    padding: 60px 0 0;
    background: none !important;
  }

  .manydesignlayouts-desktopimg {
    display: none !important;
  }
  .manydesignlayouts-left .manydesignlayouts-mobileimg .mobile-img {
    width: 100% !important;
    object-fit: contain;
  }
  .manydesignlayouts-mobileimg {
    display: block !important;
  }
  .manydesignlayouts-block {
    background: #fff;
    padding: 40px 0;
  }
  .manydesignlayouts-blockinner {
    padding: 40px 0 0;
    margin: 0;
    background: #fafafa;
    flex-direction: column-reverse;
    gap: 20px;
  }
  .manydesignlayouts-right,
  .manydesignlayouts-left {
    width: 100%;
  }
  .manydesignlayouts-blockinner h4,
  .manydesignlayouts-blockinner p {
    padding: 0 20px;
    width: 100%;
  }
  .manydesignlayouts-blockinner .manydesigns-img-container {
    display: none;
  }
  .manydesignlayouts-blockinner h4 label {
    width: auto;
  }
  .manydesignlayouts-blockinner > span {
    position: relative !important;
    left: 0;
    top: 0;
    bottom: 0;
    margin: 35px 0 -35px;
    float: left;
    width: 100% !important;
  }
  .manydesignlayouts-mobileimg span {
    width: 100% !important;
  }

  .cleankosher-block {
    margin: 70px 0 50px 0;
  }
  .cleankosher-blockinner {
    padding: 50px 20px 30px;
  }
  .manydesignlayouts-blockinner img,
  .cleankosher-blockinner img {
    -o-object-position: center;
    object-position: center;
  }

  .cleankosher-blockinner h4,
  .cleankosher-blockinner p {
    width: 100%;
  }
  .cleankosher-blockinner > span {
    right: auto;
    position: relative !important;
    top: auto;
    bottom: auto;
    width: 100% !important;
    margin: 30px 0 0 !important;
  }
  .cleankosher-blockinner::before,
  .cleankosher-blockinner::after {
    right: 0;
    background-size: auto 100%;
    height: 10px;
    top: -10px;
  }
  .cleankosher-blockinner::after {
    left: 0;
    top: auto;
    bottom: -30px;
  }
  .cleankosher-block .col-md-12::before {
    width: 60px;
    height: 60px;
    bottom: -12px;
    left: 0px;
  }
  .manydesignlayouts-blockinner h4,
  .cleankosher-blockinner h4,
  .kg-feature-single h4,
  .kosherai-block-right h4,
  .section-container .text-container h4 {
    font-size: 28px;
    line-height: 34px;
  }

  .kosherai-block {
    padding: 70px 0;
  }
  .kosherai-block-right {
    width: 100%;
    order: inherit;
  }
  .kosherai-block-left {
    width: 100%;
    margin: 30px 0 0;
  }
  .partners-block .section-container {
    flex-direction: column;
    justify-content: start;
    padding: 100px 30px;
  }

  .section-container .image-container,
  .section-container .text-container {
    width: 100%;
  }

  .p-100,
  .pg-100,
  .userhomepgcontent {
    padding: 50px 15px;
  }

  .pg-100 {
    padding-top: clamp(0px, 6vw, 50px);
  }
}

@media (max-width: 480px) {
  .pg-100 {
    padding: 50px 0;
    padding-top: clamp(0px, 6vw, 50px);
  }
}

@media (max-width: 420px) {
  .plentydesignlayouts-heading h4 > span {
    margin: 0px auto 20px !important;
    display: table !important;
  }

  .home-products-block h3,
  .plentydesignlayouts-heading h4,
  .magicautofill-block-left h4 {
    flex-wrap: wrap;
  }
}
