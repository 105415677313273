/* Poppins Light */
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/Poppins-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

/* Poppins Light Italic */
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/Poppins-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

/* Poppins Regular */
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/Poppins-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Poppins Italic */
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/Poppins-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

/* Poppins Medium */
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/Poppins-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* Poppins Medium Italic */
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/Poppins-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

/* Poppins SemiBold */
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/Poppins-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* Poppins SemiBold Italic */
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/Poppins-SemiBoldItalic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

/* Poppins Bold */
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/Poppins-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Poppins SemiBold Italic */
@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/Poppins-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

/* Roboto Regular */
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/roboto/Roboto-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Roboto Italic */
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/roboto/Roboto-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

/* Roboto Medium */
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/roboto/Roboto-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* Roboto Medium Italic */
@font-face {
  font-family: 'Roboto';
  src: url('/fonts/roboto/Roboto-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
