.undo-buttons {
  display: flex;
  gap: 5px;
  margin-left: 10px;

  .editor-icon {
    &:disabled {
      cursor: not-allowed;

      &:hover {
        filter: unset;
        background-color: unset;
      }
      &:active {
        transform: unset;
      }
      svg {
        path {
          fill: #848080ab;
        }
      }
    }
  }
}

.editor-icon {
  display: flex;
  border: none;
  outline: transparent;
  background: transparent;
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding: 8px;
  color: #fff;
  border-radius: 50%;
  transition: 0.2s;
  font-family: var(--kg-primary-font);

  &:hover {
    filter: drop-shadow(0 0 0.75rem var(--kg-primary));
    background-color: hsla(0, 0%, 100%, 0.07);
  }
  &:active {
    transform: scale(0.75);
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.backbtn {
  display: table;
  margin-right: 5px;
  &.logo {
    margin-right: 20px;
  }
}

.backbtn span {
  float: left;
  font-weight: 500;
  font-size: 18px;
}

.backbtn img {
  max-height: 35px !important;
  float: left;
}

.editor-right-buttons {
  display: flex;
  gap: 8px;
}

.topbar-btn {
  padding: 6px 12px !important;
  margin: 0px;
  line-height: 24px;
  text-transform: uppercase;
  font-size: 13px;
  background: transparent;
  font-weight: 600;
  color: #fff !important;
  box-shadow: none !important;
  border: 1px solid #fff;
  border-radius: 5px;

  .button-text {
    display: inline-flex;
    align-items: center;
    gap: 5px;
  }

  pre {
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    background: #fff;
    display: flex;
    width: 17px;
    height: 17px;
    color: var(--kg-primary);
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    line-height: 17px;
    display: inline-flex;
    margin-right: 5px;
  }

  .chakra-button__icon {
    color: #fff;
    outline: none !important;
    > svg:focus {
      outline: none !important;
    }
  }

  &:hover {
    span {
      background: var(--linear-gradient);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .chakra-button__icon {
      color: var(--kg-primary);
      svg stop:first-child {
        stop-color: var(--kg-secondary);
      }
      svg stop:last-child {
        stop-color: var(--kg-primary);
      }
    }

    pre {
      background: var(--linear-gradient);
      background-size: 300% 100%;
      background-position: 25% 25%;
      color: #fff;
      -webkit-text-fill-color: #fff;
      background-clip: unset;
      --webkit-background-clip: unset;
    }
  }

  &:disabled {
    &:hover {
      background: transparent;
    }
  }
}

.editor-popover-portal {
  .bp5-overlay-content,
  .bp5-popover,
  .bp5-popover-content {
    border-radius: 8px !important;
  }

  .editor-popover {
    border-radius: 8px;

    .bp5-popover-arrow {
      svg path {
        fill: #fff;
      }
    }
  }

  .editor-tooltip {
    font-size: 12px;
    border-radius: 8px;
    text-align: center;
    .editor-tooltip-code {
      margin-top: 5px;

      display: flex;
      gap: 5px;
      align-items: center;
      > span {
        display: inline-block;
        background: #d9d9d9;
        border-radius: 5px;
        padding: 2px 4px;
        height: 20px;
      }
    }
  }
}

.toolbar-color-picker {
  margin-left: 6px;
  border-radius: 8px;
  border: 0.5px solid gray;
  cursor: pointer;
  div {
    box-shadow: unset !important;
    border-radius: 8px !important;
  }
}

.polotno-toolbar,
.canvas-tooltip {
  button.bp5-button {
    height: 35px !important;
    padding: 14px !important;
    border-radius: 8px !important;
    color: #1a202c !important;

    svg {
      fill: #1a202c !important;
    }
    &:hover {
      background: #edf2f7;
    }
  }
}

.canvas-tooltip {
  button.bp5-button {
    width: 35px;
    height: 35px;
  }

  .bp5-button-text {
    display: none;
  }

  .bp5-icon {
    margin-right: 0;
  }
}

.show-more-popover {
  .editor-divider {
    display: none;
  }
}

@media (max-width: 800px) {
  .topbar-btn {
    padding: 6px !important;
    margin: 0 2px;
    background-color: transparent !important;
    min-width: inherit !important;

    > span {
      margin: 0;
      padding: 3px;
    }

    .chakra-button__icon {
      margin: 0;
    }

    .topbar-btn-text,
    .button-text {
      display: none;
    }
  }

  .bp5-button.topbar-btn .bp5-icon {
    color: #fff;
    margin: 0px !important;
  }

  .polotno-outerblock > .bp5-navbar > div {
    display: block;
  }

  .bp5-navbar-group.bp5-align-right.projectpg-topbarrightblock {
    float: right;
  }

  .projectpg-titleblock {
    display: none;
  }
}

@media (max-width: 480px) {
  .backbtn.logo {
    display: none;
  }
}
