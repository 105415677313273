// /* Faq Page Css */

// .accordion {
//   margin: 20px 0;
//   display: table;
//   width: 100%;
// }

// .accordion__item {
//   border: 0px;
//   margin: 10px 0;
//   transition: 0.25s;
// }

// .accordion__button {
//   color: #352f36;
//   cursor: pointer;
//   padding: 13px 15px 13px 40px;
//   width: 100%;
//   text-align: left;
//   border: none;
//   background: #fff;
//   position: relative;
// }

// .accordion__button:hover {
//   box-shadow:
//     0 1px 3px 0 rgb(0 0 0 / 0.1),
//     0 1px 2px -1px rgb(0 0 0 / 0.1);
// }

// .accordion__button:before {
//   display: inline-block;
//   content: '';
//   height: 10px;
//   width: 10px;
//   margin-right: 12px;
//   border-bottom: 2px solid currentColor;
//   border-right: 2px solid currentColor;
//   transform: rotate(-45deg);
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 15px;
//   margin: auto;
// }

// .accordion__button[aria-expanded='true'] {
//   box-shadow:
//     0 1px 3px 0 rgb(0 0 0 / 0.1),
//     0 1px 2px -1px rgb(0 0 0 / 0.1);
// }

// .accordion__button[aria-expanded='true']::before,
// .accordion__button[aria-selected='true']::before {
//   transform: rotate(45deg);
//   top: -5px;
// }

// [hidden] {
//   display: none;
// }

// .accordion__panel {
//   padding: 20px;
//   animation: fadein 0.35s ease-in;
// }

.faq-container {
  max-width: 800px;
  margin: 0 auto;
  .pg-content {
    float: unset;
  }
}

.faq-header {
  margin-bottom: 15px;
  width: unset !important;
}

.faq-head-panel {
  padding: 10px 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  &:hover {
    background: #f9fafb;
    border-radius: 8px;
  }
}

.faq-header-text {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #111111;

  @media (max-width: 768px) {
    font-size: 18px;
  }
}

.faq-panel-text {
  background: #f9fafb;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  p,
  li {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 21px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #333d4a;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: #111111 !important;
    background: none;
    background-clip: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
    font-weight: unset;
    font-family: var(--kg-primary-font);
    display: table;
    width: 100%;
  }
}

.css-199rzq3 {
  transform: rotate(-90deg);
}
