.button-contained {
  padding: 8px 17px;
  font-weight: 500;
  font-size: 16px;
  background: linear-gradient(
    91deg,
    var(--kg-secondary),
    var(--kg-primary),
    var(--kg-secondary)
  );
  border-radius: 10px;
  border-width: 3px;
  border: transparent;
  opacity: 1;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  white-space: nowrap;
  background-size: 300% 100%;
  -o-transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;

  &:hover {
    background-position: 100% 0;
    box-shadow: 0 1px 5px 0 #9f70bc;
    color: #fff;
  }

  &:active {
    transform: scale(0.95);
  }

  &.sm {
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 5px;
  }

  &.lg {
    padding: 13px 20px;
    font-size: 20px;
    border-radius: 8px;
    border-width: 3px;
  }
}

.button-outlined {
  display: block;
  position: relative;
  padding: 9px 17px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: 0.3s;

  &::before {
    box-sizing: border-box;
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 10px;
    background-size: 300% 100%;
    border: 2px solid transparent;
    background: linear-gradient(
        90.57deg,
        var(--kg-secondary) -5.35%,
        var(--kg-primary) 88%
      )
      border-box;
    -webkit-mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    transition: 0.3s;
  }

  span {
    display: inline-block;
    font-size: 16px;
    background: linear-gradient(
      91deg,
      var(--kg-secondary) -5.35%,
      var(--kg-primary) 88%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    font-weight: 500;
    z-index: 1;
    transition: 0.1s;
  }

  &:hover {
    &::before {
      animation: show-background 0.3s ease-in-out;
      -webkit-mask-composite: unset;
      mask-composite: unset;
      transition: 0.3s;
      box-shadow: 0 1px 5px 0 var(--kg-light-primary);
    }
    span {
      color: #fff;
      background: unset;
      background-clip: unset;
      -webkit-background-clip: unset;
      -webkit-text-fill-color: unset;
      z-index: 2;
      position: relative;
    }
  }

  &:active {
    transform: scale(0.95);
  }

  &.sm {
    padding: 6px 12px;
    border-radius: 5px;

    &::before {
      border: 2px solid transparent;
      border-radius: 5px;
    }

    span {
      font-size: 14px;
    }
  }

  &.lg {
    font-size: 20px;
    border-radius: 8px;

    span {
      font-size: 20px;
    }

    &::before {
      border: 3px solid transparent;
    }
  }
}
