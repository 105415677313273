.welcome-message-block {
  padding: 0 15px;
  display: none;
}

.welcome-message {
  max-width: 1100px;
  margin: 15px auto;
  border-radius: 20px;
  position: relative;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  padding: 20px 30px 20px 20px;
  filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.2));
  backdrop-filter: blur(6px);
  background: linear-gradient(
    91deg,
    var(--kg-secondary) -5.35%,
    var(--kg-primary) 88%
  );
}

.welcome-message .text-container > div,
.welcome-message .text-container > p {
  margin: 12px 0;
}

.welcome-message strong {
  color: #fff;
  font-family: var(--kg-primary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
}

.welcome-message-block.show {
  display: block;
  position: relative;
  z-index: 9;
}

.welcome-message .close-icon {
  cursor: pointer;
  padding: 3px;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  right: 8px;
  transition: 0.2s;
}

.welcome-message a {
  font-weight: 500;
  color: #fff;
  font-style: italic;
}
.welcome-message a:hover {
  text-decoration: underline;
}

.welcome-message .close-icon span {
  display: flex;
}

.welcome-message .close-icon:hover {
  background: #807c7c6c;
}

.welcome-message .text-container p,
.welcome-message .text-container div {
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  text-align: left;
  color: #fff;
}
