/* Profile Page Css */

.profilepg-outer form {
  float: left;
  width: 100%;
}

.profilepg-outer form .col {
  float: left;
  position: relative;
}

.profilepg-outer form .col-6 {
  width: 49%;
}

.profilepg-outer form .col:nth-child(2n) {
  float: right;
}

.profilepg-outer form label {
  float: left;
  width: 100%;
  font-family: var(--kg-primary-font);
  color: #000;
  font-size: 16px;
  margin: 0 0 7px;
  line-height: 20px;
}

.profilepg-outer form .form-control {
  padding: 9px 10px;
  font-size: 14px;
  color: #000;
  line-height: 20px;
  border-color: #999999 !important;
  box-shadow: none !important;
  border-radius: 6px;
}

.profilepg-outer form .form-select {
  padding: 10px;
  font-size: 14px;
  color: #000;
  line-height: 20px;
  border-color: #999999 !important;
  box-shadow: none !important;
  width: 100%;
  font-family: var(--kg-primary-font);
  margin: 0;
  border-radius: 5px;
}

.profilepgcontent .profilepg-outer form .form-control {
  padding: 5px 10px;
}

.profilepg-outer form .btn,
.profilepg-outer form .form-control {
  width: 100%;
  font-family: var(--kg-primary-font);
  margin: 0;
  border-radius: 5px;
}

.profilepg-outer form .btn {
  padding: 13px;
  font-size: 16px;
  color: #fff !important;
  text-transform: uppercase;
  line-height: 16px;
  border: 0;
  border-radius: 10px;
  background: var(--linear-gradient);
  font-weight: 700;
  max-width: 300px;
}

.profilepg-formouter {
  float: left;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  margin: 0 0 20px;
  border-radius: 7px;
}

.pgcontentinner .profilepg-formouter {
  padding: 40px 0;
  margin: 0px;
  border-bottom: 1px solid #5c5c5c;
  border-radius: 0;
}

.pgcontentinner .profilepg-outer .profilepg-formouter:last-child {
  border-bottom: 0px;
}

.pgcontentinner .profilepg-outer.sitesettingsblock-outer .profilepg-formouter {
  border-bottom: 1px solid #5c5c5c;
}

.profilepg-formouter h4 {
  color: #000;
  font-size: 24px;
  line-height: 25px;
  font-weight: 600;
  text-transform: inherit;
  margin: 0 0 30px;
  font-family: var(--kg-primary-font);
  position: relative;
}

.profilepg-formouter h4 .btn {
  position: absolute;
  right: 0;
  padding: 0;
  line-height: 23px;
  font-size: 15px;
  font-weight: 700;
  background: var(--linear-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* color: #456BFB !important; */
  text-transform: uppercase;
  border: 0px !important;
}

.profilepg-formouter .btn,
.link-button,
.forgot-password.text-right .btn,
.forgot-password .bp5-button-text {
  transition: 0.1s;
}

.profilepg-formouter .btn:hover,
.profilepg-formouter h4 .btn:hover,
.link-button:hover {
  color: #6b7280 !important;
  -webkit-text-fill-color: #6b7280 !important;
}

.pgcontentinner .profilepg-formouter .btn:hover {
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
  background: var(--kg-primary) !important;
}

.pgcontentinner .profilepg-formouter .btn.profilepg-btn:hover {
  color: #6b7280 !important;
  -webkit-text-fill-color: #6b7280 !important;
  background: #fff !important;
}

.profilepg-formouter-header {
  float: left;
  width: 100%;
  margin: 0 0 30px;
  position: relative;
}

.profilepg-formouter .profilepg-formouter-header h4 {
  margin: 0px;
}

.profilepg-formouter-header p {
  font-family: var(--kg-primary-font);
  color: #6b7280;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 7px 0 0;
}

.profilepg-formouter-header-tooltip {
  position: absolute;
  right: 0;
  top: 0;
}
.profilepg-formouter-header-tooltip span {
  height: 25px !important;
  float: left;
  cursor: pointer;
}
.profilepg-formouter-header-tooltipcontent {
  position: absolute;
  top: calc(100% + 7px);
  width: 300px;
  right: 0;
  padding: 10px;
  border-radius: 5px;
  background: var(--linear-gradient);
  display: none;
}
.profilepg-formouter-header-tooltipcontent::before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid var(--kg-primary);
  content: '';
  position: absolute;
  top: -7px;
  right: 10px;
}
.profilepg-formouter-header-tooltipcontent p {
  font-size: 12px;
  line-height: 18px;
  margin: 0;
  color: #fff;
}
.profilepg-formouter-header-tooltip:hover
  .profilepg-formouter-header-tooltipcontent {
  display: block;
}

.profilepg-outer form.sitesettingsblock .col-6 {
  width: 48.5%;
}

.resetpass-btn {
  padding: 0;
  line-height: 15px;
  font-size: 15px;
  font-weight: 700;
  background: var(--linear-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  margin: 34px 0 0;
  float: left;
  cursor: pointer;
}

.profilepg-outer form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.profilepg-outer form .col-3 {
  width: 24%;
}

.profilepg-outer form .col-4 {
  width: 32%;
}

.profilepg-outer form .col-6 {
  width: 49%;
}

.profilepg-outer form input:read-only {
  padding-left: 0px !important;
  border-color: #fff !important;
  font-weight: 700 !important;
}

.profilepg-outer form input.form-control[type='file'] {
  padding: 10px !important;
  font-weight: normal !important;
  border-color: #999 !important;
}

.passwordchange-block {
  margin: 20px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.passwordchange-block .resetpass-btn {
  margin-left: 30px;
}

.passwordchange-block .form-control {
  font-weight: 700;
}

.profilepg-outer form.autofillform .col-4 {
  width: 21.5%;
}
.profilepg-outer form.autofillform .col-4:nth-child(1),
.profilepg-outer form.autofillform .col-4:nth-child(2) {
  width: 36%;
}
.profilepg-outer form.autofillform .col-4:nth-child(3) {
  width: 24%;
}
.profilepg-outer form.autofillform .col-4:nth-last-child(2) {
  width: 55px;
}

.settings-email-div {
  width: 50% !important;
}

.settings-email-field {
  width: 100% !important;
}

/* To be removed Shortly */

@media (max-width: 1020px) {
  .settings-form div input {
    padding-right: 0px !important;
  }
}

@media (max-width: 991px) {
  .profilepg-outer form.autofillform .col-4 {
    width: 32%;
  }
  .profilepg-outer form.autofillform .col-4:nth-last-child(2) {
    margin: 0 auto 1rem 15px;
  }
}

@media (max-width: 767px) {
  .settings-email-div {
    width: 100% !important;
  }

  .profilepg-outer form .col-3,
  .profilepg-outer form .col-4 {
    width: 49%;
  }
  .profilepg-outer form.sitesettingsblock .col-6 {
    width: 100%;
  }
  .profilepg-outer form.autofillform .col-4:nth-child(1),
  .profilepg-outer form.autofillform .col-4:nth-child(2),
  .profilepg-outer form.autofillform .col-4:nth-child(3),
  .profilepg-outer form.autofillform .col-4 {
    width: 49%;
  }
}

@media (max-width: 520px) {
  .profilepg-outer form .col-6 {
    width: 100%;
  }

  .profilepg-outer form.autofillform .col-4:nth-child(1),
  .profilepg-outer form.autofillform .col-4:nth-child(2),
  .profilepg-outer form.autofillform .col-4:nth-child(3),
  .profilepg-outer form.autofillform .col-4 {
    width: 100%;
  }
  .profilepg-outer form.autofillform .col-4:nth-last-child(2) {
    margin: 0 auto 1rem 0;
  }
}

@media (max-width: 450px) {
  .settings-form div input {
    font-size: 12px !important;
  }
}

@media (max-width: 420px) {
  .profilepg-outer form .col-3,
  .profilepg-outer form .col-4 {
    width: 100%;
  }
}
