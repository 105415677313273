.ai-write-container {
  position: relative;
}

.ai-write-spinner-container {
  width: 35px;
  height: 35px;
  text-align: center;
}

.ai-write-popup {
  position: absolute;
  padding: 8px 4px;
  background: white;
  border-radius: 12px;
  top: 48px;
  left: -76.5px;
  z-index: 999;
}

.ai-write-popup > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 180px;
}

.ai-write-popup > ul > li {
  padding: 4px 8px;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  border-radius: 8px;
}

.ai-write-popup > ul > li:hover {
  background: #f3f4f6;
}

.ai-write-popup > ul > li:nth-of-type(4) {
  margin-bottom: 4px;
}

.ai-write-popup > ul > li:nth-of-type(5):hover {
  background: white;
}

.ai-write-popup > ul > li:nth-of-type(5) {
  padding: 4px 8px 2px 8px;
  color: #6b7280;
  font-size: 11px;
  line-height: 16.5px;
  border-top: 1px solid #d1d5db;
  border-radius: 0px;
  cursor: default;
}
