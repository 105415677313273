.polotno-panel-container:has(.effects-section) {
  overflow-y: auto;
  scrollbar-color: #e5e7eb #9ca3af;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e5e7eb;
  }

  &::-webkit-scrollbar-thumb {
    background: #9ca3af;
  }
}

.effects-section {
  position: relative;

  .effects-close-icon {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

.effects-tiles-container {
  .effects-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 16px 0 12px;

    .effects-header-icon {
      display: flex;
    }
    .effects-header-text {
      font-family: Poppins;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0%;
      color: #333d4a;
    }

    .effects-header-end {
      margin-left: auto;
      font-family: Poppins;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0%;
      color: var(--editor-light-text);
      cursor: pointer;
      transition-duration: 0.25s;
      &:hover {
        color: var(--elements-panel-header);
      }
    }
  }
  .effects-tile {
    border: 1px solid #d1d5db;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 12px;
    transition: 0.25s;

    &:hover {
      cursor: pointer;
      border: 1px solid #b6bcc5;
    }

    &.selected {
      border: 1px solid var(--kg-primary);
      box-shadow:
        0 1px 3px 0 rgb(0 0 0 / 0.1),
        0 1px 2px -1px rgb(0 0 0 / 0.1);
    }
  }
  .effects-tile-top {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .effects-text {
    font-weight: 500;
  }

  .effects-action {
    margin-left: auto;

    .checkbox-root {
      background-color: white;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #d1d5db;
      padding: 2px;
    }

    .checkbox-root[aria-checked='true'],
    .checkbox-root[data-checked] {
      border: 1px solid var(--kg-primary);

      .checkbox-indicator {
        width: 100%;
        height: 100%;
        background: var(--linear-gradient);
        border-radius: 2px;
      }
    }
  }

  .effects-tile-bottom {
    margin-top: 10px;
    margin-bottom: 8px;
    .slider-header-text {
      font-size: 12px;
      font-weight: 400;
      color: #4b5563;
    }

    .effects-color-picker {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;
      > span > div,
      > span > div > div {
        width: 50px !important;
        height: 28px !important;
        border-radius: 8px !important;
      }
    }
  }

  .filter-images-preview {
    display: flex;
    gap: 10px;
    cursor: pointer;
    align-items: center;
    overflow-x: scroll;

    &.no-flex-scroll {
      overflow-x: visible;
      flex-wrap: wrap;
    }

    .filter-image-container {
      padding: 8px;
      border: 1px solid #d1d5db;
      border-radius: 8px;
      transition: 0.25s;

      &:hover {
        border: 1px solid #b6bcc5;
      }

      &:active {
        transform: scale(0.98);
      }

      &.active {
        border: 1px solid var(--kg-primary);
        box-shadow:
          0 1px 3px 0 rgb(0 0 0 / 0.1),
          0 1px 2px -1px rgb(0 0 0 / 0.1);
      }

      .filter-image {
        width: 80px;
        height: 80px;
        margin-bottom: 5px;
        border-radius: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .filter-image-text {
        font-family: Poppins;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0%;
        text-align: center;
        text-transform: capitalize;
      }
    }
  }
}
