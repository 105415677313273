/* Orders List Css */

.orderlistouter {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.orderlist-single {
  background: #fff;
  float: left;
  width: 32%;
  padding: 8px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #9ca3afc9;
  margin: 0 2% 13px 0;
}

.orderlist-single:nth-child(3n) {
  margin-right: 0;
}

.orderlist-single-left {
  float: left;
  width: 31%;
  background: #f3f4f6;
  border-radius: 10px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.orderlist-single-left > span {
  top: 7px !important;
  bottom: 7px !important;
  left: 7px !important;
  right: 7px !important;
}

.orderlist-single-left-img {
  border-radius: 8px !important;
  object-fit: contain;
}

.orderlist-single-right {
  margin-left: auto;
  width: 66%;
}

.orderlist-single-righttop,
.orderlist-single-rightbottom {
  float: left;
  width: 100%;
  margin: 0;
}

.orderlist-single-righttop h4 {
  font-size: 16px;
  line-height: 26px;
  color: #000;
  font-weight: 400;
  float: left;
  font-family: var(--kg-primary-font);
  width: 100%;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.orderlist-single-rightmiddle {
  display: flex;
  /* align-items: center; */
  float: left;
  gap: 5px;
  width: 100%;
  margin-bottom: 30px;
}

.orderlist-single-rightmiddle label {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  float: left;
  font-family: var(--kg-primary-font);
  margin: 0px;
}

.orderlist-single-rightmiddle p {
  font-size: 14px;
  color: #4b5563;
  font-weight: 400;
  float: left;
  font-family: var(--kg-primary-font);
  margin: 0;
}

.orderlist-single-rightbottom {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  border: 0.8px solid #d1d5db;
  width: auto;
  padding: 7px 10px;
  border-radius: 10px;
}

.orderlist-single-rightbottom .btn {
  background: transparent !important;
  border: 0px;
  padding: 0;
  display: flex;
  width: 20px;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}

.pageLink {
  cursor: pointer;
}

.pageItem,
.pageItemActive {
  float: left;
  width: 2rem;
  height: 2rem;
  border: 1px solid #969696;
  border-radius: 6px;
  cursor: pointer;
  color: #969696;
  margin: 0 5px;
}

.pageItemActive {
  border: 1px solid var(--kg-primary);
  color: #fff;
  background-color: var(--kg-primary);
}

.pageItem a,
.pageItemActive a {
  float: left;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.pageItem a:hover {
  color: #969696;
}

.pageItemActive a {
  color: #fff;
}

@media (max-width: 767px) {
  .orderlist-single {
    width: 49%;
  }

  .orderlist-single:nth-child(3n) {
    margin-right: 2%;
  }

  .orderlist-single:nth-child(2n) {
    margin-right: 0;
  }
}

@media (max-width: 420px) {
  .orderlist-single {
    width: 100%;
    margin: 0px 0px 16px 0;
  }
  .orderlist-single:nth-child(3n) {
    margin-right: 0px;
  }

  .orderlist-single:nth-child(2n) {
    margin-right: 0px;
  }
}
