.master-animation-container .polotno-side-panel {
  background-color: #e7e7e7 !important;
}

.master-animation-container .polotno-side-panel .go3136444743 {
  display: none;
}

.master-animation-container .go3960841851.polotno-panel-container {
  background: #f9fafb;
  overflow: auto;
  scrollbar-color: #e5e7eb #9ca3af;
  scrollbar-width: thin;
}

.video-animation-section {
  padding: 6px;
}

.video-animation-section .animate-container > h3,
.video-animation-section .animation-heading-container > h3,
.animation-section > h3 {
  color: #333d4a;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 12px;
}

.animation-section > h3:first-of-type {
  display: inline-block;
  width: calc(100% - 32px);
  vertical-align: middle;
  margin-top: 0px;
  margin-bottom: 0px;
}

.animation-section .close-animation-btn {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.video-animation-section .close-animation-btn {
  float: right;
}

.animation-section .none-animation-btn {
  width: 100%;
  text-align: center;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  height: 50px;
  margin-top: 25px;
  position: relative;
  background: white;
  cursor: pointer;
}

.animation-section .none-animation-btn > div {
  display: inline-block;
  vertical-align: middle;
}

.animation-section .none-animation-btn > span {
  vertical-align: middle;
}

.video-animation-section .animate-container {
  padding-bottom: 16px;
  border-bottom: 1px solid #e5e7eb;
}

.video-animation-section .animate-container > h3,
.video-animation-section .animation-heading-container > h3,
.video-animation-section .animation-heading-container > h3:first-of-type {
  margin: 0px;
  line-height: 32px;
  display: inline-block;
  width: unset;
}

.video-animation-section .animation-heading-container {
  height: 36px;
  margin: 16px 0;
  margin-top: 24px;
}

.video-animation-section .animation-heading-container:nth-of-type(2) {
  margin-top: 16px;
}

.video-animation-section .animation-remove-all-btn {
  border-radius: 8px;
  border: 1px solid #d1d5db;
  padding: 9px 16px;
  color: #333d4a;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  float: right;
}

.animation-row-container {
  margin-bottom: 4px;
}

.animation-row-container > div {
  display: inline-block;
  text-align: center;
  width: calc(33% - 3px);
}

.animation-row-container > div:nth-of-type(2) {
  margin: 0 6px;
}

.animation-row-container > div > span {
  font-size: 12px;
  line-height: 18px;
}

.animation-row-container > div > button {
  position: relative;
  background: white;
  border-radius: 5.8px;
}

.animation-row-container svg {
  border-radius: 5.8px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.animation-section .none-animation-selected {
  border: none;
}

.animation-section .none-animation-selected::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 8px;
  background: var(--linear-gradient);
  z-index: -1;
  pointer-events: none;
}

.video-animation-section .animation-direction-selected,
.animation-section .animation-selected {
  border: none !important;
}

.video-animation-section .animation-direction-selected::after,
.animation-section .animation-selected::after {
  content: '';
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 5.8px;
  background: var(--linear-gradient);
  z-index: -1;
  pointer-events: none;
}

.animation-section .animation-settings {
  padding: 16px;
}

.animation-section .animation-slider > span {
  display: block;
  color: #4b5563;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.animation-section .animation-slider .animation-slider-slider {
  width: calc(100% - 46px);
  margin-right: 6px;
  vertical-align: middle;
}

.animation-section
  .animation-slider
  .animation-slider-slider
  .chakra-slider__filled-track {
  --slider-bg: var(--linear-gradient);
  background: var(--linear-gradient);
}

.animation-section .animation-slider .animation-slider-track {
  height: 8px;
  border-radius: 16px;
}

.animation-section .animation-slider .animation-slider-thumb {
  height: 24px;
  width: 24px;
  border-radius: 48px;
  border: 2px solid #d1d5db;
}

.animation-section .animation-slider .animation-slider-input {
  width: 40px;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.video-animation-section .animation-direction > span {
  display: block;
  color: #4b5563;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}

.animation-section .animation-settings .animation-settings-btn-container {
  height: 50px;
}

.animation-section
  .animation-settings
  .animation-settings-btn-container
  > button {
  width: calc(33% - 8px);
  vertical-align: top;
  border-radius: 5.8px;
  height: 39px;
  border: 1px solid #d1d5db;
  background: white;
  position: relative;
}

.animation-section
  .animation-settings
  .animation-settings-btn-container
  > button:nth-of-type(2) {
  margin: 0 12px;
}

.video-animation-section .video-animation-settings {
  border-radius: 8px;
  border: 1px solid #d1d5db;
}

.video-animation-section .direction-button-container {
  display: flex;
  gap: 12px;
  margin-top: 5px;
  margin-bottom: 24px;
}

.video-animation-section .direction-button-container button {
  width: calc(25% - 9px);
  border-radius: 5.8px;
  border: 1px solid #d1d5db;
  padding: 9px 0;
  position: relative;
  background: white;
}

.video-animation-section .direction-button-container button > div > div {
  display: flex;
  justify-content: center;
}

.video-animation-settings .animation-slider,
.video-animation-settings .animation-direction {
  margin-top: 12px;
}
