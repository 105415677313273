.product-page-related-products {
  display: grid;
  width: 100%;
  margin: 10px auto;
  grid-gap: 20px;
  padding: 20px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-rows: auto;
  justify-items: center;
}

.relatedproduct-single {
  width: 100%;
  transition: 0.25s;
}

.relatedproduct-single > a > .relatedproduct-single-imgouter {
  transition: 0.25s;
}

.relatedproduct-single > a:hover > .relatedproduct-single-imgouter {
  transform: scale(0.95);
}

.relatedproduct-single > a:hover {
  cursor: pointer;
}

.relatedproduct-single-imgouter span {
  position: unset !important;
  width: 100% !important;
  float: left;
  margin: 0 0 5px !important;
}

.relatedproduct-single-img {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
  border-radius: 8px !important;
}

.relatedproduct-single h4 {
  float: left;
  width: 100%;
  font-size: 21px;
  margin: 0px;
  font-family: var(--kg-primary-font);
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.relatedproduct-single h4.smaller {
  font-size: 15px;
}

.product-userinfo {
  position: relative;
  display: flex;
  margin: 0 0 30px;
  align-items: center;
  font-size: 13px;
  color: #5d5f61;
}
.product-userinfo a {
  color: #000;
  margin-left: 5px;
}
.product-userlogo {
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
  margin-right: 10px;
}
.kglogo span {
  width: 150px !important;
  margin-left: 1px !important;
}
.product-userlogo img {
  object-fit: contain;
  object-position: center;
}
.product-userlogo.kglogo img {
  object-position: left;
}
