.slide-container {
  margin: 10px 0;
}
.slide-container .bp5-slider {
  padding: 0 10px;
  z-index: 10;
}

.progress-container {
  margin: 10px 0;
}

.check-container {
  margin: 10px 0;
}

.check-container label {
  float: unset !important;
}

.select-page-input-container {
  position: relative;
}

.select-page-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #898989;
  border-radius: 6px;
  padding: 14px;
  color: #000;
  font-size: 15px;
  text-align: left;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.select-page-input-container::after {
  position: absolute;
  right: 13px;
  top: 0;
  bottom: 0;
  height: 22px;
  line-height: 22px;
  content: '\f107';
  font-size: 22px;
  font-family: FontAwesome;
  margin: auto;
  color: #000;
}

.select-page-input.error {
  border: 1px solid #f00;
}

.select-page-dropdown {
  width: 100%;
  background: #fff;
  padding: 10px 0;
  border: 1px solid #898989a4;
  border-radius: 6px;
  box-shadow:
    0 0 0 1px rgba(64, 87, 109, 0.07),
    0 2px 12px rgba(53, 71, 90, 0.2);
}

.select-page-list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
}

.select-page-item {
  padding: 0.3rem 1rem;
}

.select-page-item label {
  margin-bottom: 0px !important;
  padding: 4px 0;
}

.select-page-item .select-page-label {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 0;
  color: #000;
}

.select-page-item:hover,
.select-page-item.active {
  background: rgb(64 87 109 / 7%);
}

.quality-settings {
  font-size: 10px;
  text-align: left;
  color: #6b7280;
}

.quality-multiplier {
  border: 1px solid #d1d5db;
  border-radius: 8px;
  padding: 3px 8px;
  flex-shrink: 0;
  color: #000;
  display: flex;
  gap: 4px;
}

.quality-multiplier span {
  color: #9ca3af;
  font-size: 12px;
}

.quality-multiplier span.number {
  color: #374151;
  font-size: 12px;
}

.download-icon {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
}

.download-icon svg {
  width: 20px;
  height: 20px;
}

.download-name {
  font-weight: 500;
  font-size: 14px;
}
