.mask-image-holder .go729245683 {
  background-color: unset !important ;
}

.mask-image-holder .go2452636514 {
  border-radius: 0;
}

.mask-image-holder .polotno-close-panel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mask-image-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  h5 {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    margin: 0;
  }
}

.polotno-panel-container:has(.mask-image-holder) {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #e5e7eb #9ca3af;
}
