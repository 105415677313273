.upload-multi-select button {
  width: unset;
}

.upload-multi-select ul {
  padding: 10px 0;
  background-color: #686868;
  border: solid 1px #fff !important;
  color: #000;
}

.upload-multi-select .chakra-input {
  background-color: #0e1318;
  border: solid 1px #686868 !important;
  padding: 7px;
  border-radius: 2px;
  color: #fff !important;
  box-shadow: none !important;
}

.upload-multi-select .chakra-input svg {
  fill: #fff;
}
