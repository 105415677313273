.autowidth {
  width: auto;
}

.save-msg {
  margin: 0px;
  float: right;
  align-items: center;
  height: 100%;
  color: #fff;
  transition: display 1s;
  width: 30px;
}

.inline-block {
  display: inline-block;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.p-100 {
  padding: 50px 100px;
}

.pg-100 {
  padding: 50px 10px;
}

.pb-100 {
  padding-bottom: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.w-full {
  width: 100%;
}

.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center;
}

.pr-10 {
  padding-right: 10px;
}

.bp5-skeleton {
  animation: 1000ms linear infinite alternate skeleton-glow;
  background: rgba(211, 216, 222, 0.2);
  background-clip: padding-box !important;
  border-color: rgba(211, 216, 222, 0.2) !important;
  border-radius: 2px;
  box-shadow: none !important;
  color: transparent !important;
  cursor: default;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bp5-skeleton::before,
.bp5-skeleton::after,
.bp5-skeleton * {
  visibility: hidden !important;
}

@media (max-width: 520px) {
  .footer_center ul li {
    width: 45%;
  }

  .container {
    --bs-gutter-x: 1.5rem !important;
  }

  .footer_center ul li a,
  .footer_copyright p {
    font-size: 14px;
  }

  .footer_copyright p span {
    margin: 0;
  }

  .catproduct-single {
    width: 50%;
  }

  .profilepg-outer form .col-6 {
    width: 100%;
  }

  .polotno-outerblock > .bp5-navbar {
    overflow: inherit;
  }

  .profilepg-outer form.autofillform .col-4:nth-child(1),
  .profilepg-outer form.autofillform .col-4:nth-child(2),
  .profilepg-outer form.autofillform .col-4:nth-child(3),
  .profilepg-outer form.autofillform .col-4 {
    width: 100%;
  }
  .profilepg-outer form.autofillform .col-4:nth-last-child(2) {
    margin: 0 auto 1rem 0;
  }
}

@media (max-width: 500px) {
  .chat-widget {
    top: unset;
    bottom: 20px;
  }
}

@media (max-width: 480px) {
  .pg-100 {
    padding: 50px 0;
    padding-top: clamp(0px, 6vw, 50px);
  }

  .category-section {
    padding: 0;
  }

  .home-slider-block h2 {
    font-size: clamp(24px, 7vw, 35px);
  }
}
