.catdetail-block {
  float: left;
  width: 100%;
  padding: 1px;
  border-radius: 20px;
  margin: 0 0 25px;
  position: relative;
  background: var(--linear-gradient);
}

.catdetail-blockinner {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 20px;
  position: relative;
  display: flex;
  align-items: flex-end;
}

.catdetail-block-left {
  float: left;
  padding: 60px;
  margin-bottom: auto;
  margin-top: auto;
}

.catdetail-block-left h4 {
  font-size: 48px;
  line-height: 52px;
  font-family: var(--kg-primary-font);
  color: #000;
  float: left;
  width: 100%;
  margin: 0 0 18px;
  padding: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.catdetailblock-desc {
  float: left;
  width: 100%;
  font-size: 20px;
  line-height: 30px;
  color: #000;
  font-weight: 300;
  font-family: var(--kg-primary-font);
}

.catdetailblock-desc p {
  font-size: 20px;
  line-height: 30px;
  font-family: var(--kg-primary-font);
  color: #000;
  font-weight: 300;
  float: left;
  width: 100%;
  margin: 0;
  padding: 0px;
}

.catdetail-block-right {
  float: right;
  padding: 0;
  display: flex;
  justify-content: center;
  border-radius: 0 20px 20px 0;
  margin-left: auto;
  position: relative;
}

/* .catdetail-block-right::after, */
.catdetail-block-right .sprinkles {
  content: '';
  width: 380px;
  height: 157px;
  right: 120px;
  top: 2px;
  /* background-image: url('/images/catdesc-bgafter.png'); */
  background-position: left top;
  background-repeat: no-repeat;
  background-size: auto;
  position: absolute;
  transform: scale(1.2);
}

.catdetail-block-right-img {
  position: relative;
  background-position: right bottom;
  background-repeat: no-repeat;
  float: right;
  background-size: 344px 342px;
  min-width: 344px;
  min-height: 392px;
  z-index: 9;
}

.catdetailblock-img {
  min-width: inherit !important;
  min-height: inherit !important;
  -o-object-fit: cover;
  object-fit: cover;
  width: 278px !important;
  height: 278px !important;
  top: auto !important;
  left: -52px !important;
}

.products-emptylisting {
  float: left;
  min-height: 450px;
  width: 100%;
  text-align: center;
  font-size: 30px;
  line-height: 40px;
}

.catproducts-pg {
  padding: 60px 0;
  width: 100%;
  float: left;
}

.catproducts-listingouter {
  float: left;
  width: 100%;
}

.catproducts-listingouter h2 {
  font-size: 40px;
  line-height: 45px;
  color: #1a1a1a;
  font-weight: 600;
  float: left;
  font-family: var(--kg-primary-font);
  width: 100%;
  margin: 0 0 20px;
}

.catproducts-listing {
  flex-wrap: wrap;
  width: 100%;
  float: left;
  display: flex;
  margin: 0 0 30px;
}

.catproducts-listingouter:last-child .catproducts-listing {
  margin: 0px;
}

@media (max-width: 1100px) {
  .catdetail-block-left h4 {
    font-size: 45px;
    line-height: 50px;
  }
  .catdetailblock-desc p {
    font-size: 16px;
    line-height: 27px;
  }
}

@media (max-width: 767px) {
  .catproducts-pg .catdetail-block-right {
    display: none;
  }

  .catproducts-pg .catdetail-block-left {
    width: 100% !important;
    text-align: center;
    padding: 50px 20px !important;
  }

  .catproducts-pg .catdetail-block-left h4 {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .catproducts-pg .catdetail-block-left .catdetailblock-desc p {
    text-align: center;
    font: normal normal normal 16px/25px var(--kg-primary-font);
    letter-spacing: -0.03px;
    color: #000000;
    opacity: 1;
  }
}

@media (max-width: 580px) {
  .catdetail-block-left {
    width: 100%;
  }

  .catdetail-block-right {
    width: 100%;
    border-radius: 0;
  }
}
