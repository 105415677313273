.file-menu-container .tooltip-popover {
  width: 100%;
}

.file-menu-container .editable .tooltip-popover {
  width: unset;
}

.file-menu-container .selected-menu-item {
  color: var(--kg-primary) !important;
}

.context-menu-container .custom-context-menu-icon svg {
  margin-left: -4px;
  margin-right: -4px;
}

.context-menu-container .custom-context-menu-icon svg > path {
  fill: #5f6b7c;
}