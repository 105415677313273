.featuredproducts {
  float: left;
  width: 100%;
  position: relative;
  z-index: 9;
}

.catproduct-single {
  float: left;
  width: 20%;
  margin: 20px 0;
  padding: 0 10px;
  transition: transform 0.2s;
  position: relative;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #7a787d1a;
  overflow: hidden;
  border-radius: 13px;
}

.catproduct-single:hover {
  transform: scale(1.1);
}

.catproduct-single a {
  text-decoration: none;
}

.catproduct-single button {
  border: 0;
  background: transparent;
  width: 100%;
  float: left;
  padding: 0;
  text-align: inherit;
  font-weight: inherit;
  color: inherit;
}

.catproduct-single span {
  float: left;
}

.catproduct-single img {
  border-radius: 13px !important;
}

.catproduct-single label {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #f3f4f6 0% 0% no-repeat padding-box;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 14px;
}

.catproduct-single label span {
  color: #6b7280;
}

.catproduct-single h4 {
  float: left;
  width: 100%;
  font-size: 18px;
  line-height: 25px;
  margin: 7px 0 0;
  font-family: var(--kg-primary-font);
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.featuredproduct-single {
  float: left;
  margin: 0 2% 15px 0;
  width: 15%;
}

.featuredproduct-single:last-child {
  margin-right: 0;
}

.featuredproduct-single a {
  text-decoration: none;
}

.featuredproduct-single button {
  border: 0;
  background: transparent;
  width: 100%;
  float: left;
  padding: 0;
  text-align: inherit;
  font-weight: inherit;
  color: inherit;
}

.featuredproduct-single-imgouter {
  width: 100%;
  position: relative;
  display: table;
  height: 100%;
}

.featuredproduct-single-imgouter.bp5-skeleton {
  min-height: 200px;
}

.featuredproduct-single-imgouter span {
  position: unset !important;
  width: 100% !important;
  float: left;
  margin: 0 0 5px !important;
}

.featuredproduct-single img {
  transition: 0.25s;
}

.featuredproduct-single:hover img {
  transform: scale(0.95);
}

.catproduct-single .featuredproduct-single-imgouter span {
  margin: 0px !important;
}

.featuredproduct-single-img {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
  border-radius: 8px !important;
}

.featuredproduct-single h4 {
  float: left;
  width: 100%;
  font-size: 21px;
  margin: 0px;
  font-family: var(--kg-primary-font);
  color: #000;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.featuredproduct-single h4.smaller {
  font-size: 15px;
}

.featuredproduct-single label {
  font-size: 16px;
  line-height: 25px;
  margin: 0 6px 0 0;
  font-family: var(--kg-primary-font);
  color: #000;
  font-weight: normal;
  position: relative;
}

@media (max-width: 991px) {
  .featuredproduct-single,
  .view_product_modal .featuredproduct-single {
    width: 32%;
  }

  .featuredproduct-single:nth-child(3n) {
    margin-right: 0px !important;
  }

  .catproduct-single {
    width: 25%;
  }
}

@media (max-width: 767px) {
  .catproduct-single {
    width: 33.3%;
  }

  .featuredproduct-single,
  .view_product_modal .featuredproduct-single {
    width: 49%;
  }

  .featuredproduct-single:nth-child(3n) {
    margin-right: 2% !important;
  }

  .featuredproduct-single:nth-child(2n) {
    margin-right: 0px !important;
  }

  .featuredproduct-single h4 {
    font-size: 14px;
    line-height: 24px;
  }
}

@media (max-width: 520px) {
  .catproduct-single {
    width: 50%;
  }
}

@media (max-width: 360px) {
  .featuredproducts {
    column-count: 1;
  }

  .catproduct-single {
    width: 100%;
    padding: 0px;
  }
}
