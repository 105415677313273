.pg-content.resetpassword-pg {
  text-align: center;
}
.resetpassword-pg .contactpg-outer {
  margin: 0 auto 20px;
  float: none;
  display: table;
}
.resetpassword-pg .profilepg-outer form .form-control {
  padding: 8px 10px;
}
.resetpassword-pg .profilepg-outer form .btn {
  width: auto;
  padding: 14px 30px;
}
.resetpassword-pg .profilepg-outer form .btn:hover {
  background: var(--kg-primary) !important;
}
