$sizes: (100, 200, 300, 400, 500, 600);
$fonts: (12, 14, 16, 20, 24, 28, 32, 40, 60);

.gradient-text {
  background: linear-gradient(
    91deg,
    var(--kg-secondary) -5.35%,
    var(--kg-primary) 88%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.italic {
  font-style: italic;
}

.light-text {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #6b7280;
}

@each $size in $sizes {
  .fw-#{$size} {
    font-weight: $size;
  }
}

@each $font in $fonts {
  .font-#{$font} {
    font-size: #{$font}px;
  }
}
