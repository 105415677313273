.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  max-width: 400px;
  margin: 50px auto;
  background: #ffffff;
  box-shadow: 0px 0 5px rgba(34, 35, 58, 0.2);
  padding: 40px 50px;
  border-radius: 15px;
  transition: all 0.3s;
  width: 100%;
}

.auth-wrapper .form-control:focus {
  border-color: var(--kg-primary);
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: var(--kg-primary);
}

.auth-inner h4 {
  font-size: 25px;
  font-family: var(--kg-secondary-font);
  color: var(--kg-primary);
  line-height: 32px;
  text-align: center;
  margin: 0 0 20px;
  text-transform: uppercase;
}
