:root {
  --kg-primary-rgb: 174, 41, 255;
  --kg-secondary-rgb: 250, 67, 133;

  --kg-primary: rgb(var(--kg-primary-rgb));
  --kg-secondary: rgb(var(--kg-secondary-rgb));

  --kg-light-primary: rgb(var(--kg-primary-rgb), 0.316);
  --kg-light-secondary: rgb(var(--kg-secondary-rgb), 0.263);

  --kg-hover-primary: rgb(var(--kg-primary-rgb), 0.08);
  --kg-hover-secondary: rgb(var(--kg-secondary-rgb), 0.08);

  --linear-gradient: linear-gradient(
    90.57deg,
    var(--kg-secondary) -5.35%,
    var(--kg-primary) 88%
  );

  --light-gradient: linear-gradient(
    90.57deg,
    var(--kg-light-secondary) -5.35%,
    var(--kg-light-primary) 88%
  );
  --hover-gradient: linear-gradient(
    90.57deg,
    var(--kg-hover-secondary) -5.35%,
    var(--kg-hover-primary) 88%
  );

  --kg-primary-font: 'Poppins', sans-serif;
  --kg-secondary-font: 'Roboto', sans-serif;

  --polotno-side-tabs-bg: #f3f4f6;
  --polotno-tabs-bg: #f9fafb;
  --editor-tabs-text: #1f2937;
  --editor-light-text: #9ca3af;
  --editor-text: #1f2937;
  --editor-light-border: #d1d5db;
  --editor-border: #738ba9ce;
  --elements-panel-header: #6b7280;
  --elements-light-text: #6b7280;
  --polotno-elements-bg: #e5e7eb;
}
